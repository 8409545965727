import { configureStore } from "@reduxjs/toolkit";
import { applyMiddleware, combineReducers, createStore } from "redux";
import logger from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "remote-redux-devtools";
import newAuthReducer from "./auth/newReducer";
import eventReducer from "./event/reducer";
import scheduleReducer from "./schedule/reducer";
import userReducer from "./user/reducer";
import ticketReducer from "./ticket/reducer";
import orgReducer from "./organization/reducer";
import teamReducer from "./team/reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const rootReducer = combineReducers({
  userAuthSync: newAuthReducer,
  eventAsync: eventReducer,
  userAsync: userReducer,
  scheduleAsync: scheduleReducer,
  ticketAsync: ticketReducer,
  orgAsync: orgReducer,
  teamAsync: teamReducer,
});

const configStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

export const persistor = persistStore(store);

export type AppDispatch = typeof configStore.dispatch;

export type AppState = ReturnType<typeof rootReducer>;
