import {
  CreateOrganizationRequest,
  Address,
  IOrganizationState,
  OrganizationType,
  Organization,
} from "./type";
import { initialCreateUserRequest } from "../user/reducer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createOrgAsync, getOrgsAsync } from "./action";

export let initialOrganization: Organization = {
  id: "",
  name: "",
  phone: "",
  address: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  },
  type: OrganizationType.UNKNOWN,
};

export let initialAddress: Address = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
};

export let initialCreateOrgRequest: CreateOrganizationRequest = {
  name: "",
  address: initialAddress,
  phone: "",
  teams: [],
  type: OrganizationType.UNKNOWN,
  admin: initialCreateUserRequest,
  ticketingManager: initialCreateUserRequest,
};

export let initialState: IOrganizationState = {
  error: "",
  isLoading: false,
  createOrgRequest: initialCreateOrgRequest,
  getOrgsResponse: [],
};

const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {},
  extraReducers: {
    [createOrgAsync.fulfilled.toString()]: (state) => {
      state.isLoading = false;
    },
    [createOrgAsync.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getOrgsAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Organization>>
    ) => {
      state.isLoading = false;
      state.getOrgsResponse = action.payload;
    },
  },
});

export default orgSlice.reducer;
