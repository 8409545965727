import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sendTicketAsync, validTicketAsync } from "../ticket/action";
import { Ticket } from "../ticket/type";
import { getTeamsForOrganizationAsync } from "./action";
import { ITeamState, Team, TeamType } from "./type";

export let initialTeam: Team = {
  id: "",
  name: "",
  type: TeamType.UNKNOWN,
};

export let initialGetTeamsResponse: Team[] = [];

export const initialState: ITeamState = {
  isLoading: false,
  error: "",
  getTeamsForOrganizationResponse: [],
};

const TeamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: {
    [getTeamsForOrganizationAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Team>>
    ) => {
      state.getTeamsForOrganizationResponse = action.payload;
    },
  },
});

export default TeamSlice.reducer;
