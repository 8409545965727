import { Card, Carousel, Col, Row } from "antd";
import logo from "../../images/logo.png";
import ExcitedFans_2 from "../../images/ExcitedFans_2.jpg";
import TicketOffice from "../../images/TicketOffice.jpg";
import "./home.css";
import ReactPlayer from "react-player";

export default function Home() {
  return (
    <div className="home">
      <div className="home-video-container">
        <ReactPlayer
          url={"https://wellcall-app-cdk.s3.amazonaws.com/intro.mp4"}
          className="home-video"
        />
      </div>
      <div className={"card-header"}>
        <h1>Sell Tix. Comp Tix. We'll Help You Manage.</h1>
      </div>
      <div className="card-wrapper">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <div className="cards-row">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="home-card"
            >
              <Card title="Why We'll Call Tix?" bordered={false}>
                We understand managing tickets can be cumbersome, we're here to
                ease the pain. Let us manage the tickets for your special guest
                and allow you to drop the pen and paper. All tickets can be
                scanned and validated here as well for your events.
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div>
                <img alt={"slide-1"} className={"slides"} src={logo} />
              </div>
            </Col>
          </div>
          <div className="cards-row">
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div>
                <img alt={"img-2"} className={"slides"} src={ExcitedFans_2} />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="home-card"
            >
              <Card title="Events of All Type" bordered={false}>
                Initially we focused on basketball, baseball/softball, and
                football, but we have now grown to cover events of all types.
                Have a concert, let the band invite their guest. Ticketing list
                are delivered before each event.
              </Card>
            </Col>
          </div>
          <div className="cards-row">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={12}
              xl={12}
              xxl={12}
              className="home-card"
            >
              <Card title="Easy as 1, 2, 3" bordered={false}>
                Register. Upload/Add Event(s). Upload/Add User(s). From there,
                all users will see a schedule of events and can begin inviting.
                When users add invites, tickets are delivered immediatedly via
                email with details of the invite. When guest arrive at the game,
                tickets can be validated here.
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <div>
                <img alt={"img-3"} className={"slides"} src={TicketOffice} />
              </div>
            </Col>
          </div>
          <div className={"section-header-container"}>
            <h1>Still Not Convinced?</h1>
          </div>
          <div className={"why-us-section"}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Card title="Same Process, New Way" bordered={false}>
                With We'll Call Tix, you will be able to sell tickets to your
                events and know who is coming to your events prior to the events
                starting. Worried about tickets being shared? No worries, our
                scanner will tell you who the ticket is intended for. Have an
                event that doesn't need to be for the public sale, simply mark
                the event as not for public sale and we will keep your event
                private.
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Card title="Peace of Mind">
                With COVID impacting many of us in 2020, contact tracing has
                become part of all our vocabulary. When individuals scan into
                your events, we will keep a simple record of who arrived and who
                didn't to assist you with contact tracing and more. We'll Call
                Tix wants to help your organization improve the security and
                peace of mind of your customers.
              </Card>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <Card title="Reporting">
                No more of the long nights post game counting cash and coins.
                With We'll Call Tix we will provide the analytics for your
                events so you know how many tickets were sold, how many tickets
                were given out by your performers, and much more. With our
                reporting you will be able to manage staffing more efficiently.
                You will now know in advance when staffing needs to increaase
                due to high demand ticket sales.
              </Card>
            </Col>
          </div>
        </Row>
      </div>
    </div>
  );
}
