import React, { useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import logo from "./../../images/logo.png";
import CheckoutForm from "./checkoutForm";
import { ITicketState } from "../../redux/ticket/type";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";
import { RouteComponentProps } from "react-router-dom";
import "./checkout.css";

type TParams = {
  client_secret: string;
};

export default function Checkout({ match }: RouteComponentProps<TParams>) {
  const promise = loadStripe(
    // "pk_test_51JVIytBqcVIyLR7FJhOqIZ3nUhnxJkXVy0mY4UG97bl3AJrk9LOTavjzCTGvJCmHRltGgHiKLWuA29kwGG4oKhON00Gz0w5QiS"
    "pk_live_51JVIytBqcVIyLR7FS8yBdpCKsSU3uIk2so81SKkJnwIY5ptijm6TFpZqWeITvFe8DiZbzk3ALRgAUWErvTLb9BR200ZZ7q1RqW"
  );

  return (
    <div className="checkout-container">
      <Elements stripe={promise}>
        <CheckoutForm client_secret={match.params.client_secret} />
      </Elements>
      {/* <StripeCheckout
                amount = {1000}
                name="We'll Call Tix"
                // functions defined above can be used to add more information while making the API call.
                // description={`Order of ${computeQuantity(cart)} items!`}
                image={logo}
                stripeKey="pk_test_51JVIytBqcVIyLR7FJhOqIZ3nUhnxJkXVy0mY4UG97bl3AJrk9LOTavjzCTGvJCmHRltGgHiKLWuA29kwGG4oKhON00Gz0w5QiS"
                currency="USD"
                email='USER_EMAIL'
                //token is for the response from the payment_intent (/api/tickets/purchase) response
                token={() => console.log('')}
                />   */}
    </div>
  );
}
