import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createScheduleAsync,
  getSchedulesAsync,
  setSeasonPassAsync,
} from "./action";
import {
  CreateScheduleRequest,
  IScheduleState,
  Schedule,
  SetSeasonPassRequest,
} from "./type";

export let initialCreateScheduleRequest: CreateScheduleRequest = {
  name: "",
  startDate: new Date(),
  endDate: new Date(),
};

export let initialSchedule: Schedule = {
  name: "",
  startDate: new Date(),
  endDate: new Date(),
  id: "",
};

export let initialSeasonPassRequest: SetSeasonPassRequest = {
  scheduleId: "",
  isSeasonPassAvailable: false,
  pricePerSeasonPass: "0.00",
  maxSeasonPassAvailable: "0",
};

export const initialState: IScheduleState = {
  isLoading: false,
  error: "",
  createScheduleRequest: initialCreateScheduleRequest,
  createScheduleResponse: initialSchedule,
  getSchedulesResponse: [],
  seasonPassRequest: initialSeasonPassRequest,
};

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {},
  extraReducers: {
    [createScheduleAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Schedule>
    ) => {
      state.createScheduleResponse = action.payload;
    },
    [setSeasonPassAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Schedule>
    ) => {
      state.createScheduleResponse = action.payload;
    },
    [getSchedulesAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Schedule>>
    ) => {
      state.getSchedulesResponse = action.payload;
    },
  },
});

export default scheduleSlice.reducer;
