import axios, { AxiosRequestConfig } from "axios";

const environmentUrl = "https://" + window.location.hostname;
// const environmentUrl = "http://localhost:80";

const client = axios.create({
  baseURL: environmentUrl,
});

client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("AUTH_TOKEN");

    if (token) {
      config.headers["authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Wrapper for axios' requests.
export const httpGetRequest = function (options: AxiosRequestConfig) {
  const onSuccess = function (response: { data: any }) {
    return response.data;
  };
  const onError = function (error: { response: any; message: any }) {
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

// Wrapper for axios' requests.
export const httpPostRequest = function (url: string, requestBody: any = null) {
  const onSuccess = function (response: { data: any }) {
    return response.data;
  };
  const onError = function (error: { response: any; message: any }) {
    return Promise.reject(error.response || error.message);
  };

  return client.post(url, requestBody).then(onSuccess).catch(onError);
};

// Wrapper for axios put requests.
export const httpPutRequest = function (options: string, data: any) {
  const onSuccess = function (response: { data: any }) {
    return response.data;
  };
  const onError = function (error: { response: any; message: any }) {
    return Promise.reject(error.response || error.message);
  };

  return client.put(options, data).then(onSuccess).catch(onError);
};

// Wrapper for axios put requests.
export const httpDeleteRequest = function (options: AxiosRequestConfig) {
  const onSuccess = function (response: { data: any }) {
    return response.data;
  };
  const onError = function (error: { response: any; message: any }) {
    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export default {
  httpGetRequest,
  httpPostRequest,
  httpPutRequest,
};
