import { ScheduleOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import { Calendar, DatePicker, Modal, notification } from "antd";
import moment from "moment";
import { array } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import { createEventAsync } from "../../redux/event/action";
import { initialCreateEventRequest } from "../../redux/event/reducer";
import { EventLocation } from "../../redux/event/type";
import { getSchedulesAsync } from "../../redux/schedule/action";
import { initialSchedule } from "../../redux/schedule/reducer";
import { IScheduleState } from "../../redux/schedule/type";

export default function NewEvent() {
  const dispatch: AppDispatch = useDispatch();
  const [eventScheduleId, setEventScheduleId] = useState(initialSchedule.id);
  const [publicSale, setPublicSale] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [eventTime, setEventTime] = useState("");

  const scheduleState: IScheduleState = useSelector(
    (state: AppState) => state.scheduleAsync
  );

  useEffect(() => {
    try {
      dispatch(getSchedulesAsync());

      notification.success({
        message: "Got Schedules",
      });
    } catch (error) {
      notification.error({
        message: "Failed To Get Schedules",
      });
    }
  }, []);

  const [eventRequest, setEventRequest] = useState(initialCreateEventRequest);

  const handleSubmit = async () => {
    try {
      setEventRequest({
        ...eventRequest,
        eventDate: eventDate + " " + eventTime,
      });

      const result = await dispatch(createEventAsync(eventRequest));

      unwrapResult(result);

      notification.success({
        message: "Created Event",
        description: "Successfully saved event.",
      });
    } catch (error) {
      notification.error({
        message: "Failed To Create Event",
        description: "Failed to create event. Please try again.",
      });
    }
  };

  function ScheduleDropdown() {
    let content: JSX.Element[] = [];

    content.push(<option value="">Select Schedule For Event</option>);
    if (scheduleState.getSchedulesResponse) {
      scheduleState.getSchedulesResponse.forEach((schedule) => {
        content.push(<option value={schedule.id}>{schedule.name}</option>);
      });
    }

    return (
      <select
        value={eventScheduleId}
        required
        onChange={(e) => {
          setEventRequest({ ...eventRequest, scheduleId: e.target.value });
          setEventScheduleId(e.target.value);
        }}
      >
        {content}
      </select>
    );
  }

  function handlePublicSaleChange() {
    if (!eventRequest.isForPublicSale) {
      setPublicSale(!publicSale);
    } else {
      setEventRequest({ ...eventRequest, isForPublicSale: false });
    }
  }

  return (
    <div>
      <h4>Add New Event</h4>
      <ScheduleDropdown />
      <form hidden={eventScheduleId === ""}>
        <div>
          <label>Name</label>
          <input
            type={"text"}
            id={"eventName"}
            placeholder={"Murray State Racers @ Tennessee State University"}
            required
            onChange={(e) =>
              setEventRequest({ ...eventRequest, name: e.target.value })
            }
          />
        </div>
        <div>
          <label>Event Date</label>
          <DatePicker
            picker={"date"}
            onChange={(e) => setEventDate(e!.format("LL"))}
          />
        </div>
        <div>
          <label>Event Time</label>
          <DatePicker
            picker={"time"}
            onChange={(e) => setEventTime(e!.format("LT"))}
          />
        </div>
        <div>
          <label>Location</label>
          <select
            required
            id={"location"}
            onChange={(e) =>
              setEventRequest({
                ...eventRequest,
                location:
                  EventLocation[e.target.value as keyof typeof EventLocation],
              })
            }
          >
            <option value="">Select Location Type</option>
            <option value="1">Home</option>
            <option value="2">Away</option>
            <option value="3">Neutral</option>
          </select>
        </div>
        <div>
          <label>Max Tickets Per Individual</label>
          <select
            required
            id={"maxTix"}
            onChange={(e) =>
              setEventRequest({
                ...eventRequest,
                maxTicketPerIndividual: Number(e.target.value),
              })
            }
          >
            <option value="">Select Allotment</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <div>
          <label>For Sale To Public?</label>
          <input
            type="checkbox"
            checked={eventRequest.isForPublicSale}
            onClick={() => handlePublicSaleChange()}
          />
        </div>
      </form>
      <div>
        <button
          disabled={
            eventRequest.name === "" ||
            eventRequest.location === EventLocation.UNKNOWN
          }
          type="submit"
          className="btn"
          onClick={() => handleSubmit()}
        >
          Add Event
        </button>
      </div>
      <div>
        <Modal
          visible={publicSale}
          onCancel={() => {
            setEventRequest({
              ...eventRequest,
              isForPublicSale: eventRequest.isForPublicSale,
            });
            setPublicSale(false);
          }}
          onOk={() => {
            setEventRequest({ ...eventRequest, isForPublicSale: true });
            setPublicSale(false);
          }}
        >
          <label>Price Per Ticket</label>
          <input
            type="text"
            onChange={(e) => {
              setEventRequest({
                ...eventRequest,
                publicSaleMeta: {
                  ...eventRequest.publicSaleMeta,
                  pricePerTicket: e.target.value,
                },
              });
            }}
          />
          <label>Max Public Ticket</label>
          <input
            type="text"
            onChange={(e) =>
              setEventRequest({
                ...eventRequest,
                publicSaleMeta: {
                  ...eventRequest.publicSaleMeta,
                  maxTicketsForSale: Number(e.target.value),
                },
              })
            }
          />
        </Modal>
      </div>
    </div>
  );
}
