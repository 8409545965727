import { httpGetRequest, httpPostRequest } from "../../httpClient/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateScheduleRequest, SetSeasonPassRequest } from "./type";

export const createScheduleAsync = createAsyncThunk(
  "schedule/createScheduleAsync",
  async (request: CreateScheduleRequest, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/schedule", request);
      return response;
    } catch (err) {
      // rejectWithValue(err);
    }
  }
);

export const setSeasonPassAsync = createAsyncThunk(
  "schedule/setSeasonPassAsync",
  async (request: SetSeasonPassRequest, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/schedule/seasonPass", request);
      return response;
    } catch (err) {
      // rejectWithValue(err);
    }
  }
);

export const getSchedulesAsync = createAsyncThunk(
  "schedule/getSchedulesAsync",
  async () => {
    try {
      console.log("response");
      let response = await httpGetRequest({ url: "api/schedule" });
      console.log(response);
      return response;
    } catch (err) {
      // rejectWithValue(err);
    }
  }
);
