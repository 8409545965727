import { InputNumber, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";

interface ScheduleDetails {
  key: number;
  name: string;
  maxTicketsPerIndividual: number;
  start: string;
  tickets: Array<{ name: string }>;
}

export default function EditEvents() {
  const columns = [
    { title: "Event Name", dataIndex: "name", key: "name" },
    { title: "Start Time", dataIndex: "start", key: "start" },
    {
      title: "Allotment",
      dataIndex: "",
      key: "maxTicketsPerIndividual",
      render: (record: ScheduleDetails) => (
        <InputNumber
          onChange={(e) => {
            console.log(e);
          }}
          value={record.maxTicketsPerIndividual}
          min={record.tickets.length || 0}
          max={10}
        />
      ),
    },
    {
      title: "Invites",
      dataIndex: "",
      key: "y",
      render: (record: ScheduleDetails) => {
        if (record.tickets) return <span>{record.tickets.length}</span>;
      },
    },
    {
      title: "Update",
      dataIndex: "",
      key: "x",
      render: () => <button className={"btn"}>UPDATE</button>,
    },
  ];

  let data: ScheduleDetails[] = [
    {
      key: 1,
      name: "Steelers @ Browns",
      start: moment().format("llll"),
      maxTicketsPerIndividual: 1,
      tickets: [
        {
          name: "Roddy",
        },
        {
          name: "Ashuah",
        },
      ],
    },
    {
      key: 2,
      name: "Steelers vs Packers",
      start: moment().format("llll"),
      maxTicketsPerIndividual: 3,
      tickets: [
        {
          name: "Roddy",
        },
        {
          name: "Trey",
        },
      ],
    },
    {
      key: 3,
      name: "BYE",
      start: moment().format("llll"),
      maxTicketsPerIndividual: 0,
      tickets: [],
    },
    {
      key: 4,
      name: "Steelers @ Raiders",
      start: moment().format("llll"),
      maxTicketsPerIndividual: 4,
      tickets: [
        {
          name: "Rae",
        },
        {
          name: "Trae",
        },
        {
          name: "Ashuah",
        },
      ],
    },
  ];

  return (
    <div>
      <h4>Edit Events For User</h4>
      <Table columns={columns} dataSource={data} />
    </div>
  );
}
