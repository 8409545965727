import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createEventAsync,
  createEventScheduleAsync,
  getEventsByTeamIdAsync,
  getEventsForPublicSaleAsync,
  getEventsScheduleDetailsAsync,
} from "./action";
import {
  CreateEventRequest,
  CreateEventScheduleRequest,
  Event,
  EventLocation,
  IEventState,
  GetEventsScheduleDetailsResponse,
  PublicSaleMeta,
} from "./type";

export let initialEvent: Event = {
  id: "",
  name: "",
  location: EventLocation.UNKNOWN,
  ticketingManagerEmail: "",
  ticketingManagerName: "",
  organizationId: "",
  teamId: "",
  maxTicketPerIndividual: 0,
  createdTime: "",
  eventDate: "",
  isForPublicSale: false,
  publicSaleMeta: {
    maxTicketsForSale: 0,
    pricePerTicket: "0.00",
  },
};

export let initialPublicSaleMeta: PublicSaleMeta = {
  maxTicketsForSale: 0,
  pricePerTicket: "0.00",
};

export let initialCreateEventRequest: CreateEventRequest = {
  name: "",
  location: 0,
  maxTicketPerIndividual: 0,
  scheduleId: "",
  eventDate: "",
  isForPublicSale: false,
  publicSaleMeta: {
    maxTicketsForSale: 0,
    pricePerTicket: "0.00",
  },
};

export let initialCreateEventScheduleRequest: CreateEventScheduleRequest = {
  events: [],
};

export let initialGetEventScheduleDetailsResponse: GetEventsScheduleDetailsResponse = {
  events: [],
  tickets: [],
};

export const initialState: IEventState = {
  isLoading: false,
  error: "",
  createEventRequest: initialCreateEventRequest,
  createEventScheduleRequest: initialCreateEventScheduleRequest,
  getScheduleResponse: [],
  getEventResponse: initialEvent,
  createEventResponse: initialEvent,
  createEventScheduleResponse: [],
  getEventScheduleDetailsResponse: initialGetEventScheduleDetailsResponse,
  getEventsByTeamResponse: [],
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: {
    [createEventScheduleAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Event>>
    ) => {
      state.createEventScheduleResponse = action.payload;
    },
    [createEventAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Event>
    ) => {
      state.createEventResponse = action.payload;
    },
    [getEventsScheduleDetailsAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<GetEventsScheduleDetailsResponse>
    ) => {
      state.getEventScheduleDetailsResponse = action.payload;
    },
    [getEventsByTeamIdAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Event>>
    ) => {
      state.getEventsByTeamResponse = action.payload;
    },
    [getEventsForPublicSaleAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Event>>
    ) => {
      state.getScheduleResponse = action.payload;
    },
  },
});

export default eventSlice.reducer;
