import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { convertProfileToString } from "../../helpers/enumHelper";
import { AppDispatch } from "../../redux";
import { createUserAsync } from "../../redux/user/action";
import { initialCreateUserRequest } from "../../redux/user/reducer";
import { ProfileEnum } from "../../redux/user/type";

export default function NewUser() {
  const dispatch: AppDispatch = useDispatch();

  const [userRequest, setUserRequest] = useState(initialCreateUserRequest);

  async function handleSubmit() {
    await dispatch(createUserAsync(userRequest))
      .then((result) => {
        unwrapResult(result);

        if (!result.payload) throw "failed to create user";

        notification.success({
          message: "Successfully Created User",
          description: "Successfully created user. Thank you.",
        });
      })
      .catch(() => {
        notification.error({
          message: "Failed To Create User",
          description: "Failed to create user. Please try again",
        });
      });
  }

  function ProfileTypeOptions() {
    let content: JSX.Element[] = [<option>Select Profile</option>];

    for (let profile in ProfileEnum) {
      if (!isNaN(Number(profile))) {
        content.push(
          <option value={profile}>
            {convertProfileToString(Number(profile))}
          </option>
        );
      }
    }

    return (
      <select
        id={"profileType"}
        required
        onChange={(e) =>
          setUserRequest({
            ...userRequest,
            profileType:
              ProfileEnum[e.target.value as keyof typeof ProfileEnum],
          })
        }
      >
        {content}
      </select>
    );
  }

  return (
    <div>
      <h4>Add New User</h4>
      <form>
        <div>
          <label>First Name</label>
          <input
            type={"text"}
            id={"userFirstName"}
            required
            placeholder={"Jane"}
            onChange={(e) =>
              setUserRequest({ ...userRequest, firstName: e.target.value })
            }
          />
        </div>
        <div>
          <label>Last Name</label>
          <input
            type={"text"}
            id={"userLastName"}
            required
            placeholder={"Jane"}
            onChange={(e) =>
              setUserRequest({ ...userRequest, lastName: e.target.value })
            }
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type={"text"}
            id={"userEmail"}
            required
            placeholder={"Jane"}
            onChange={(e) =>
              setUserRequest({ ...userRequest, email: e.target.value })
            }
          />
        </div>
        <div>
          <label>Profile Type</label>
          <ProfileTypeOptions />
        </div>
      </form>
      <div>
        <button
          disabled={
            userRequest.email === "" ||
            userRequest.firstName === "" ||
            userRequest.lastName === ""
          }
          className={"btn"}
          onClick={() => handleSubmit()}
        >
          Add User
        </button>
      </div>
    </div>
  );
}
