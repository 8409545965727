import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialUserAuth } from "../../redux/auth/newReducer";
import { notification } from "antd";
import {
  getMyAuthAsync,
  passwordResetAsync,
  resetPasswordAsync,
  userLoginAsync,
} from "../../redux/auth/action";
import { IUserAuthState } from "../../redux/auth/type";
import { AppDispatch, AppState } from "../../redux";
import { useHistory } from "react-router";
import { unwrapResult } from "@reduxjs/toolkit";

export default function Login() {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const loginStatus: IUserAuthState = useSelector(
    (state: AppState) => state.userAuthSync
  );
  const [loginRequest, setLoginRequest] = useState(initialUserAuth);

  const handleSubmit = async () => {
    await dispatch(userLoginAsync(loginRequest))
      .then((result) => {
        if (!result.payload) throw "failed login";

        localStorage.setItem("AUTH_TOKEN", result.payload);

        notification.success({
          message: "Login Succeeded",
          description: "Welcome Back.",
        });

        history.push("/");
      })
      .catch((error) => {
        notification.error({
          message: "Login Failed",
          description: "Please check the username or password",
        });
      });
  };

  function handleReset() {
    if (!loginRequest.username) {
      notification.error({
        message: "Need Email First",
      });
      return;
    }

    dispatch(passwordResetAsync(loginRequest));
    notification.success({
      message: "An Email Has Been Sent.",
    });
  }

  return (
    <div className="login">
      <div className="col-33">
        <form>
          <label>Username</label>
          <input
            type="text"
            id="Username"
            className="login-textbox"
            required
            placeholder="your@email.com"
            onChange={(e) =>
              setLoginRequest({ ...loginRequest, username: e.target.value })
            }
          />
          <label>Password</label>
          <input
            type="password"
            id="Password"
            className="login-textbox"
            required
            onChange={(e) =>
              setLoginRequest({ ...loginRequest, password: e.target.value })
            }
          />
        </form>
        <div>
          <button className="btn" type="submit" onClick={() => handleSubmit()}>
            Submit
          </button>
        </div>
        <div>
          <button
            type={"reset"}
            className={"btn"}
            onClick={() => handleReset()}
          >
            Forgot/Reset Password
          </button>
        </div>
      </div>
      <div></div>
    </div>
  );
}
