import React, { useEffect, useState } from "react";
import { CSVDownloader, CSVReader } from "react-papaparse";
import { notification, Table } from "antd";
import { AppDispatch, AppState } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { createEventScheduleAsync } from "../../redux/event/action";
import { initialCreateEventScheduleRequest } from "../../redux/event/reducer";
import {
  CreateEventScheduleRequest,
  EventLocation,
} from "../../redux/event/type";
import { unwrapResult } from "@reduxjs/toolkit";
import { IScheduleState } from "../../redux/schedule/type";
import { initialSchedule } from "../../redux/schedule/reducer";
import { getSchedulesAsync } from "../../redux/schedule/action";

export interface CSVFile {
  data: string[];
  errors: string[];
  meta: {
    delimeter: string;
    linebreak: string;
    aborted: boolean;
    truncated: boolean;
    cursor: number;
  };
}

interface CSVRow {
  key: number;
  Event_Name: string;
  Home_Away_Neutral: string;
  Max_Tickets_Per_Individual: string;
}

export default function UploadSchedule() {
  const dispatch: AppDispatch = useDispatch();

  const initTableData: CSVRow[] = [];
  const [eventScheduleId, setEventScheduleId] = useState(initialSchedule.id);
  const [tableData, setTableData] = useState(initTableData);
  const [scheduleRequest, setScheduleRequest] = useState(
    initialCreateEventScheduleRequest
  );
  const [uploadDisabled, setUploadDisabled] = useState(true);

  const scheduleState: IScheduleState = useSelector(
    (state: AppState) => state.scheduleAsync
  );

  useEffect(() => {
    async function onLoad() {
      try {
        const result = await dispatch(getSchedulesAsync());

        unwrapResult(result);

        notification.success({
          message: "Got Schedules",
        });
      } catch (error) {
        notification.error({
          message: "Failed To Get Schedules",
        });
      }
    }

    onLoad();
  }, [dispatch]);

  const tableColumns = [
    {
      title: "Event Name",
      dataIndex: "Event_Name",
      key: "1",
    },
    {
      title: "Home/Away/Neutral",
      dataIndex: "Home_Away_Neutral",
      key: "2",
    },
    {
      title: "Max Tickets Per Individual",
      dataIndex: "Max_Tickets_Per_Individual",
      key: "3",
    },
  ];

  function handleOnDrop(data: CSVFile[], file: any) {
    const headerValues: string[] = Array.from(tableColumns, (tc) =>
      tc.dataIndex.toLowerCase()
    );
    let header = data[0];

    try {
      let csvHeaders = Array.from(header.data, (hd) =>
        hd.replace(/\s/g, "").toLowerCase()
      );
      headerValues.forEach((element) => {
        if (csvHeaders.indexOf(element) === -1) {
          throw new Error(`${element} not found in header`);
        }
      });
    } catch (e) {
      notification.error({
        message: "Invalid Header",
        description: "Please check your header.",
      });
      return;
    }

    let csvRecords = data.slice(1, data.length);
    let tableRows: CSVRow[] = parseCsvRecords(csvRecords, headerValues.length);

    setTableData(tableRows);

    notification.success({
      message: "Successful Upload",
      description: "Roster Successfully Uploaded",
    });

    setRequest(tableRows);
  }

  function setRequest(tableData: CSVRow[]) {
    let schedReq: CreateEventScheduleRequest = initialCreateEventScheduleRequest;

    tableData.forEach((event) => {
      schedReq.events.push({
        scheduleId: eventScheduleId,
        name: event.Event_Name,
        eventDate: "",
        maxTicketPerIndividual: isNaN(Number(event.Max_Tickets_Per_Individual))
          ? Number(event.Max_Tickets_Per_Individual)
          : 0,
        location:
          EventLocation[event.Home_Away_Neutral as keyof typeof EventLocation],
        isForPublicSale: false,
        publicSaleMeta: {
          maxTicketsForSale: 0,
          pricePerTicket: "0",
        },
      });
    });
    setScheduleRequest(schedReq);
    setUploadDisabled(false);
  }

  function parseCsvRecords(csvBody: CSVFile[], headerLength: number) {
    let tableRows: CSVRow[] = [];

    try {
      csvBody.forEach((line, index) => {
        if (line.data.length !== headerLength) {
          throw new Error(`Error Processing Row ${index}`);
        }

        var tableRow: CSVRow = {
          key: index,
          Event_Name: line.data[0],
          Home_Away_Neutral: line.data[1],
          Max_Tickets_Per_Individual: line.data[2],
        };

        tableRows.push(tableRow);
      });
    } catch {
      notification.error({
        message: "Invalid Row",
        description: `Error Processing Rows of CSV`,
      });
      return tableRows;
    }

    return tableRows;
  }

  function handleOnError(err: any, file: any, inputElem: any, reason: any) {
    notification.error({
      message: "CSV Upload Error",
      description: `Error Uploading CSV. Please Try Again.`,
    });
  }

  const handleUpload = async () => {
    try {
      const result = await dispatch(createEventScheduleAsync(scheduleRequest));

      unwrapResult(result);

      console.log(result);

      notification.success({
        message: "Schedule Created",
        description: "Successfully created schedule.",
      });
    } catch (error) {
      notification.error({
        message: "Schedule Failed To Create",
        description:
          "There was an error creating your schedule. Please try again.",
      });
    }
  };

  function ScheduleDropdown() {
    let content: JSX.Element[] = [];

    content.push(<option value="">Select Schedule For Event(s)</option>);

    if (scheduleState.getSchedulesResponse) {
      scheduleState.getSchedulesResponse.forEach((schedule) => {
        content.push(<option value={schedule.id}>{schedule.name}</option>);
      });
    }

    return (
      <select
        value={eventScheduleId}
        required
        onChange={(e) => {
          setEventScheduleId(e.target.value);
        }}
      >
        {content}
      </select>
    );
  }

  return (
    <div className="upload-schedule">
      <h4>Upload Multiple Events</h4>
      <ScheduleDropdown />
      <div className={"col-33"}>
        <CSVDownloader
          className={"btn"}
          data={[
            {
              Event_Name: "Opponent or Event Name",
              Home_Away_Neutral: "HOME",
              Max_Tickets_Per_Individual: "5",
            },
          ]}
          type={"button"}
          filename={"empty-upload-schedule"}
          bom={true}
        >
          Download Template
        </CSVDownloader>
        <CSVReader
          style={{ height: "auto" }}
          onDrop={(data: any, file?: any) => handleOnDrop(data, file)}
          onError={handleOnError}
        ></CSVReader>
      </div>
      <Table columns={tableColumns} dataSource={tableData} />
      <div className="col-33">
        <button
          className="btn"
          disabled={uploadDisabled && eventScheduleId === ""}
          type="submit"
          onClick={() => handleUpload()}
        >
          Upload
        </button>
      </div>
    </div>
  );
}
