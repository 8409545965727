import { CreateUserRequest, User } from "../user/type";

export interface Organization {
  id: string;
  name: string;
  phone: string;
  address: Address;
  type: OrganizationType;
}

export interface CreateOrganizationRequest {
  name: string;
  address: Address;
  phone: string;
  teams: Array<Team>;
  type: OrganizationType;
  admin: CreateUserRequest;
  ticketingManager: CreateUserRequest;
}

export interface Team {
  name: string;
  address: Address;
  ticketingManagers: Array<User>;
  league: string;
}

export enum OrganizationType {
  UNKNOWN = 0,
  SCHOOL,
  BUSINESS,
  PERSONAL,
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface IOrganizationState {
  isLoading: boolean;
  error: any;
  createOrgRequest: CreateOrganizationRequest;
  getOrgsResponse: Organization[];
}
