import { Ticket } from "../ticket/type";

export interface Event {
  id: string;
  name: string;
  location: EventLocation;
  ticketingManagerName: string;
  ticketingManagerEmail: string;
  organizationId: string;
  teamId: string;
  maxTicketPerIndividual: number;
  createdTime: string;
  isForPublicSale: boolean;
  publicSaleMeta: PublicSaleMeta;
  eventDate: string;
}

export interface PublicSaleMeta {
  pricePerTicket: string;
  maxTicketsForSale: number;
}

export interface GetEventsScheduleDetailsResponse {
  events: Event[];
  tickets: Ticket[];
}

export enum EventLocation {
  UNKNOWN = 0,
  HOME,
  AWAY,
  NEUTRAL,
}

export interface CreateEventRequest {
  name: string;
  location: EventLocation;
  maxTicketPerIndividual: number;
  scheduleId: string;
  isForPublicSale: boolean;
  publicSaleMeta: PublicSaleMeta;
  eventDate: string;
}

export interface CreateEventScheduleRequest {
  events: CreateEventRequest[];
}

export interface IEventState {
  isLoading: boolean;
  error: any;
  createEventRequest: CreateEventRequest;
  createEventScheduleRequest: CreateEventScheduleRequest;
  getScheduleResponse: Event[];
  createEventScheduleResponse: Event[];
  createEventResponse: Event;
  getEventResponse: Event;
  getEventScheduleDetailsResponse: GetEventsScheduleDetailsResponse;
  getEventsByTeamResponse: Event[];
}
