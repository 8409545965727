import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpGetRequest } from "../../httpClient/client";

export const getTeamsForOrganizationAsync = createAsyncThunk(
  "team/getTeamsForOrganizationAsync",
  async (request: string, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/team/list",
        params: { organizationId: request },
      });
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
