import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, useHistory } from "react-router";
import { AppDispatch } from "../../redux";
import { resetPasswordAsync } from "../../redux/auth/action";
import {
  initialLoginRequest,
  initialUserAuth,
} from "../../redux/auth/newReducer";

type TParams = {
  token: string;
};

export default function ResetPassword({ match }: RouteComponentProps<TParams>) {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("AUTH_TOKEN", match.params.token);
  }, []);

  const [loginRequest, setLoginRequest] = useState(initialUserAuth);
  const [passwordToMatch, setPasswordToMatch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  async function handleReset() {
    if (passwordToMatch !== loginRequest.password) {
      notification.error({
        message: "Passwords Must Match",
      });
      return;
    }

    await dispatch(resetPasswordAsync(loginRequest))
      .then((result) => {
        unwrapResult(result);

        notification.success({
          message: "Passwords Reset.",
        });

        history.push("/");
      })
      .catch((error) => {
        notification.error({
          message: "Passwords Reset Failed.",
        });
      });
  }

  return (
    <div>
      <div className="col-33">
        <form onSubmit={() => setModalOpen(true)}>
          <label>Username</label>
          <input
            type="text"
            id="Username"
            className="reset-password-textbox"
            required
            placeholder="your@email.com"
            onChange={(e) =>
              setLoginRequest({ ...loginRequest, username: e.target.value })
            }
          />
          <div>
            <label>New Password</label>
            <input
              type="password"
              id="new-password"
              className="reset-password-textbox"
              required
              onChange={(e) =>
                setLoginRequest({ ...loginRequest, password: e.target.value })
              }
            />
            <label>Confirm Password</label>
            <input
              type="password"
              id="confirm-password"
              className="reset-password-textbox"
              required
              onChange={(e) => setPasswordToMatch(e.target.value)}
            />
          </div>
        </form>
        <div>
          <button
            className="btn"
            type="submit"
            disabled={passwordToMatch !== loginRequest.password}
            onClick={() => setModalOpen(true)}
          >
            Submit
          </button>
        </div>
        <div>
          <Modal
            onCancel={() => setModalOpen(false)}
            visible={modalOpen}
            onOk={() => {
              setModalOpen(false);
              handleReset();
            }}
          >
            <p className={"modal-confirmation"}>
              Thank you for confirming your account. When we finish resetting
              your password, we will notify you via email. You will then be able
              to enjoy We'll Call.
            </p>
          </Modal>
        </div>
      </div>
    </div>
  );
}
