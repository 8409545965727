import { httpGetRequest, httpPostRequest } from "../../httpClient/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateOrganizationRequest } from "./type";

export const createOrgAsync = createAsyncThunk(
  "org/createOrgAsync",
  async (request: CreateOrganizationRequest, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/organization", request);
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getOrgsAsync = createAsyncThunk(
  "org/getOrgsAsync",
  async (undefined, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({ url: "api/organization/list" });
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
