import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import { getMyAuthAsync } from "../../redux/auth/action";
import { getMeAsync, updateUserAsync } from "../../redux/user/action";
import { IUserAuthState } from "../../redux/auth/type";
import { IUserState, ProfileEnum, UserProfile } from "../../redux/user/type";
import { Col, List, Modal, notification, Row, Typography } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory } from "react-router-dom";
import "./profile.css";
import { TeamType } from "../../redux/team/type";
import { switchProfileAsync } from "../../redux/auth/action";
import {
  convertProfileToString,
  convertTeamTypeToString,
} from "../../helpers/enumHelper";

export default function Profile() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const loginStatus: IUserAuthState = useSelector(
    (state: AppState) => state.userAuthSync
  );
  const userStatus: IUserState = useSelector(
    (state: AppState) => state.userAsync
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(userStatus.getMeResponse);

  useEffect(() => {
    dispatch(getMeAsync());
  }, []);

  async function handleProfileSwitch(profileId: string) {
    dispatch(switchProfileAsync(profileId))
      .then((result) => {
        if (!result.payload) throw "failed to switch profiles";

        localStorage.setItem("AUTH_TOKEN", result.payload);

        dispatch(getMyAuthAsync());

        window.location = window.location;
      })
      .catch();
  }

  function TeamRows() {
    return (
      <>
        <List
          header={
            <div>
              Below is a list of the profiles that you are currently added to.
              Each row contains your access and which team the access
              corresponds to. It is possible to hold multiple access levels for
              the same team.
            </div>
          }
          bordered
          dataSource={userStatus.getMeResponse.profiles}
          renderItem={(item: UserProfile) => (
            <List.Item className="profiles-list">
              <Typography.Text mark className="profiles-list-profile">
                {convertProfileToString(item.profileType)}
              </Typography.Text>
              <div className="profiles-list-team">
                {userStatus.getMeResponse.organizations.find(
                  (t) => t.id == item.organizationId
                )?.name ?? "Unknown"}{" "}
                |{" "}
                {convertTeamTypeToString(
                  userStatus.getMeResponse.teams.find(
                    (t) => t.id == item.teamId
                  )?.type ?? 0
                )}
              </div>
              <div>
                <button
                  className={"btn"}
                  onClick={() => handleProfileSwitch(item.id)}
                >
                  Switch
                </button>
              </div>
            </List.Item>
          )}
        />
      </>
    );
  }

  async function handleSubmit() {
    try {
      const result = await dispatch(updateUserAsync(userProfile));

      unwrapResult(result);

      notification.success({
        message: "Successfully Updated",
        description: "Successfully updated your profile. Thank you.",
      });
      history.push("/");
    } catch (error) {
      notification.error({
        message: "Failed To Update",
        description: "Failed to update your profile. Please try again",
      });
    }
  }

  return (
    <>
      <div className="profile-form-container">
        <div className="profile-form-input-container">
          <label className="profile-form-label">First Name</label>
          <input
            className="profile-form-input"
            type="text"
            onChange={(e) =>
              setUserProfile({ ...userProfile, firstName: e.target.value })
            }
            defaultValue={userStatus.getMeResponse?.firstName}
          />
        </div>
        <div className="profile-form-input-container">
          <label className="profile-form-label">Last Name</label>
          <input
            className="profile-form-input"
            type="text"
            onChange={(e) =>
              setUserProfile({ ...userProfile, lastName: e.target.value })
            }
            defaultValue={userStatus.getMeResponse?.lastName}
          />
        </div>
        <div className="profile-form-input-container">
          <label className="profile-form-label">Phone</label>
          <input
            className="profile-form-input"
            type="tel"
            onChange={(e) =>
              setUserProfile({ ...userProfile, phone: e.target.value })
            }
            defaultValue={userStatus.getMeResponse?.phone}
          />
        </div>
        <div className="profile-form-input-container">
          <label className="profile-form-label">Email</label>
          <input
            className="profile-form-input"
            type="email"
            disabled={true}
            defaultValue={userStatus.getMeResponse.email}
          />
        </div>
        <div className="profile-form-teams-container">
          <Row>
            <TeamRows />
          </Row>
        </div>
        <div>
          <button
            onClick={() => setModalOpen(true)}
            type="submit"
            className="btn"
          >
            Submit
          </button>
        </div>
        <div>
          <Modal
            onCancel={() => {
              setModalOpen(false);
            }}
            onOk={() => {
              setModalOpen(false);
              handleSubmit();
            }}
            visible={modalOpen}
          >
            <p className={"modal-confirmation"}>
              Thank you for keeping your profile up to date. We will update your
              information.
            </p>
          </Modal>
        </div>
      </div>
    </>
  );
}
