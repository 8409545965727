import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import { IUserAuthState } from "../../redux/auth/type";
import { getMyTeamAsync } from "../../redux/user/action";
import { IUserState, ProfileEnum, UserProfile } from "../../redux/user/type";

interface User {
  key: number;
  firstName: string;
  lastName: string;
  email: string;
  profiles: UserProfile[];
}

export default function ViewUsers() {
  const dispatch: AppDispatch = useDispatch();
  const userState: IUserState = useSelector(
    (state: AppState) => state.userAsync
  );
  const loginStatus: IUserAuthState = useSelector(
    (state: AppState) => state.userAuthSync
  );

  const [isPlayer, setIsPlayer] = useState(true);

  useEffect(() => {
    dispatch(getMyTeamAsync());

    if (loginStatus.getMeResponse) {
      setIsPlayer(
        loginStatus.getMeResponse.profiles[0].profileType === ProfileEnum.PLAYER
      );
    }
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "",
      key: "firstName",
      render: (record: User) => (
        <a
          href={`mailto:${record.email}`}
        >{`${record.firstName} ${record.lastName}`}</a>
      ),
    },
    {
      title: "Profile Type",
      dataIndex: "",
      key: "profile",
      render: (record: User) => (
        <span>{ProfileEnum[record.profiles[0].profileType]}</span>
      ),
    },
    {
      title: "Edit User",
      dataIndex: "",
      key: "y",
      render: () =>
        !isPlayer ? (
          <a href={"/edit-user"}>
            <EditOutlined />
          </a>
        ) : (
          <EditOutlined />
        ),
    },
    {
      title: "Delete",
      dataIndex: "",
      key: "x",
      render: () => <DeleteOutlined />,
    },
  ];

  return (
    <div>
      <h4>View Users</h4>
      <Table columns={columns} dataSource={userState.getMyTeamResponse} />
    </div>
  );
}
