import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpGetRequest, httpPostRequest } from "../../httpClient/client";
import { LoginRequest, UserAuth } from "./type";

export const userLoginAsync = createAsyncThunk(
  "auth/loginAsync",
  async (request: LoginRequest, { rejectWithValue }) => {
    let response = await httpPostRequest("api/auth/login", request);
    return response;
  }
);

export const getMyAuthAsync = createAsyncThunk(
  "auth/getMyAuthAsync",
  async () => {
    try {
      let response = await httpGetRequest({ url: "api/auth/me" });
      return response;
    } catch (err) {
      // rejectWithValue(err);
    }
  }
);

export const switchProfileAsync = createAsyncThunk(
  "auth/switchProfileAsync",
  async (profileId: string) => {
    try {
      let response = await httpGetRequest({
        url: "api/auth/profile/switch",
        params: { userProfileId: profileId },
      });
      return response;
    } catch (err) {
      // rejectWithValue(err);
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "auth/reset-password",
  async (auth: LoginRequest) => {
    let response = await httpPostRequest("api/auth/reset-password", auth);
    return response;
  }
);

export const passwordResetAsync = createAsyncThunk(
  "auth/password-reset",
  async (auth: LoginRequest) => {
    try {
      let response = await httpPostRequest("api/auth/password-reset", auth);
      return response;
    } catch (err) {
      // rejectWithValue(err);
    }
  }
);
