import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux";
import { createTixHolderAsync } from "../../redux/user/action";
import { initialCreateUserRequest } from "../../redux/user/reducer";

export default function TixHolderRegistration() {
  const dispatch: AppDispatch = useDispatch();

  const [userRequest, setUserRequest] = useState(initialCreateUserRequest);

  async function handleSubmit() {
    await dispatch(createTixHolderAsync(userRequest))
      .then((result) => {
        unwrapResult(result);

        if (!result.payload) throw "failed to create user";

        notification.success({
          message: "Successfully Created User",
          description: "Successfully created user. Thank you.",
        });

        window.location.assign("/");
      })
      .catch(() => {
        notification.error({
          message: "Failed To Create User",
          description: "Failed to create user. Please try again",
        });
      });
  }

  return (
    <div>
      <h4>Add New User</h4>
      <form>
        <div>
          <label>First Name</label>
          <input
            type={"text"}
            id={"userFirstName"}
            required
            placeholder={"Jane"}
            onChange={(e) =>
              setUserRequest({ ...userRequest, firstName: e.target.value })
            }
          />
        </div>
        <div>
          <label>Last Name</label>
          <input
            type={"text"}
            id={"userLastName"}
            required
            placeholder={"Jane"}
            onChange={(e) =>
              setUserRequest({ ...userRequest, lastName: e.target.value })
            }
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type={"text"}
            id={"userEmail"}
            required
            placeholder={"Jane"}
            onChange={(e) =>
              setUserRequest({ ...userRequest, email: e.target.value })
            }
          />
        </div>
      </form>
      <div>
        <button
          disabled={
            userRequest.email === "" ||
            userRequest.firstName === "" ||
            userRequest.lastName === ""
          }
          className={"btn"}
          onClick={() => handleSubmit()}
        >
          Add User
        </button>
      </div>
    </div>
  );
}
