import { unwrapResult } from "@reduxjs/toolkit";
import { notification } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import QrReader from "react-qr-reader";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import { initialEvent } from "../../redux/event/reducer";
import { validTicketAsync } from "../../redux/ticket/action";
import { initialTicket } from "../../redux/ticket/reducer";
import { ITicketState, Ticket } from "../../redux/ticket/type";
import "./validTicket.css";

export default function ValidTicket() {
  const dispatch: AppDispatch = useDispatch();
  const [qrResults, setQrResults] = useState(false);
  const [isModalVis, setIsModalVis] = useState(false);
  const [ticket, setTicket] = useState(initialTicket);

  const ticketState: ITicketState = useSelector(
    (state: AppState) => state.ticketAsync
  );

  function handleError(err: any) {
    if (err) {
      setQrResults(err.toString());
      setIsModalVis(true);
    }
  }

  async function handleScan(data: any) {
    let ticket: Ticket = initialTicket;

    if (data) ticket = JSON.parse(data);

    if (ticket && data) {
      try {
        let result = await dispatch(validTicketAsync(ticket.id));

        unwrapResult(result);

        setTicket(ticketState.validTicketResponse);

        setQrResults(true);
        setIsModalVis(true);
      } catch (error) {
        setQrResults(false);
        setIsModalVis(true);
      }
    }
  }

  function Host() {
    if (
      ticketState.validTicketResponse &&
      ticketState.validTicketResponse.host
    ) {
      return (
        <li>
          Host: {ticketState.validTicketResponse.host.firstName}{" "}
          {ticketState.validTicketResponse.host.lastName}
        </li>
      );
    } else {
      return <li>Host: Self</li>;
    }
  }

  return (
    <div>
      <div>
        <p>
          Please scan We'll Call Tix in area below. You may be asked permission
          to use your camera, to allow our site to scan tix, please allow in
          order to use our scanner appropriately.
        </p>
      </div>
      <div>
        <QrReader
          delay={3000}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%" }}
        />
      </div>
      <Modal
        title={"Valid Tix?"}
        visible={isModalVis}
        className={qrResults ? "access-granted-modal" : "access-denied-modal"}
        onCancel={() => setIsModalVis(false)}
        onOk={() => setIsModalVis(false)}
      >
        {qrResults ? "Access Granted" : "Access Denied"}
        <ol>
          <li>Event: {ticket.event.name}</li>
          <li>Start: {ticket.event.createdTime}</li>
          <li>
            Guest: {ticket.guest.firstName} {ticket.guest.lastName}
          </li>
          <Host />
        </ol>
      </Modal>
    </div>
  );
}
