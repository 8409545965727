import { Modal, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { convertTeamTypeToString } from "../../helpers/enumHelper";
import { AppDispatch, AppState } from "../../redux";
import { getEventsForPublicSaleAsync } from "../../redux/event/action";
import { initialEvent } from "../../redux/event/reducer";
import { Event, IEventState } from "../../redux/event/type";
import { getOrgsAsync } from "../../redux/organization/action";
import { IOrganizationState } from "../../redux/organization/type";
import { getTeamsForOrganizationAsync } from "../../redux/team/action";
import { ITeamState, TeamType } from "../../redux/team/type";
import { purchaseTixAsync } from "../../redux/ticket/action";
import { InviteUser, ITicketState } from "../../redux/ticket/type";
import { initialUser } from "../../redux/user/reducer";
import "./purchaseTicket.css";

export default function PurchaseTicket() {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const [isTeamsVisible, setIsTeamsVisible] = useState(false);
  const [isScheduleVisible, setIsScheduleVisible] = useState(false);
  const [purchaseOrganization, setPurchaseOrganization] = useState("");
  const [purchaseTeam, setPurchaseTeam] = useState("");
  const [purchaseFirstName, setPurchaseFirstName] = useState("");
  const [purchaseLastName, setPurchaseLastName] = useState("");
  const [purchaseEmail, setPurchaseEmail] = useState("");
  const [purchaseEvent, setPurchaseEvent] = useState(initialEvent);
  const [purchaseModalVis, setPurchaseModalVis] = useState(false);
  const [tixPurchaseQuantity, setTixPurchaseQuantity] = useState(1);
  const ticketHolders: Array<InviteUser> = [];
  const [tixHolders, setTixHolders] = useState(ticketHolders);

  const orgState: IOrganizationState = useSelector(
    (state: AppState) => state.orgAsync
  );

  const teamState: ITeamState = useSelector(
    (state: AppState) => state.teamAsync
  );

  const eventState: IEventState = useSelector(
    (state: AppState) => state.eventAsync
  );

  const tixState: ITicketState = useSelector(
    (state: AppState) => state.ticketAsync
  );

  useEffect(() => {
    dispatch(getOrgsAsync());
  }, []);

  function OrganizationOptions() {
    let content: JSX.Element[] = [];

    content.push(<option value="">Select An Organization</option>);
    if (orgState.getOrgsResponse) {
      orgState.getOrgsResponse.forEach((org, index) => {
        content.push(
          <option key={index} value={org.id}>
            {org.name}
          </option>
        );
      });
    }

    return (
      <select
        required
        value={purchaseOrganization}
        onChange={(e) => handleOrganizationSelection(e.target.value)}
      >
        {content}
      </select>
    );
  }

  function TeamOptions() {
    let content: JSX.Element[] = [];

    content.push(<option value="">Select A Team</option>);
    if (teamState.getTeamsForOrganizationResponse) {
      teamState.getTeamsForOrganizationResponse.forEach((team, index) => {
        content.push(
          <option key={index} value={team.id}>
            {team.name} | {convertTeamTypeToString(team.type)}
          </option>
        );
      });
    }

    return (
      <select
        required
        value={purchaseTeam}
        onChange={(e) => handleTeamSelection(e.target.value)}
      >
        {content}
      </select>
    );
  }

  function TicketsForSale() {
    let eventsDataSource: any[] = [];
    let events = eventState.getScheduleResponse;

    if (events) {
      events.forEach((event, index) => {
        eventsDataSource.push({
          ...event,
          start: moment(event.eventDate).format("llll"),
        });
      });
    }

    return <Table columns={columns} dataSource={eventsDataSource} />;
  }

  function handleOrganizationSelection(orgId: any) {
    setPurchaseOrganization(orgId);
    dispatch(getTeamsForOrganizationAsync(orgId));
    setIsTeamsVisible(true);
  }

  function handleTeamSelection(teamId: any) {
    setPurchaseTeam(teamId);
    dispatch(getEventsForPublicSaleAsync(teamId));
    setIsScheduleVisible(true);
  }

  function openPublicSaleModal(event: Event) {
    setPurchaseEvent(event);
    setPurchaseModalVis(true);
  }

  async function handlePurchase() {
    let purchaseQuantity: number = Number(tixPurchaseQuantity);
    let price: number = Number(purchaseEvent.publicSaleMeta.pricePerTicket);

    let subTotal: number = purchaseQuantity * price;

    let purchasedTickets: InviteUser[] = [];

    tixHolders.forEach((tix) => {
      purchasedTickets.push({
        firstName: tix.firstName,
        lastName: tix.lastName,
        email: tix.email,
        profileType: 0,
      });
    });

    await dispatch(
      purchaseTixAsync({
        eventId: purchaseEvent.id,
        purchaseQuantity: tixPurchaseQuantity,
        price: purchaseEvent.publicSaleMeta.pricePerTicket,
        subtotal: subTotal.toString(),
        purchasedTix: purchasedTickets,
      })
    )
      .then((response) => {
        setPurchaseModalVis(false);

        history.push("/checkout/" + response.payload.clientSecret);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const columns = [
    { title: "Event Name", dataIndex: "name", key: "name" },
    { title: "Start Time", dataIndex: "start", key: "start" },
    {
      title: "Purchase Tix",
      dataIndex: "",
      key: "purchase",
      render: (record: Event) => {
        if (moment(record.eventDate).isBefore(moment().toISOString())) {
          return (
            <div>
              <strong>Previous Game</strong>
            </div>
          );
        } else if (record.publicSaleMeta.maxTicketsForSale <= 0) {
          return (
            <div>
              <strong>Sold Out</strong>
            </div>
          );
        } else {
          return (
            <>
              <button
                className={"btn"}
                onClick={() => openPublicSaleModal(record)}
              >
                Purchase
              </button>
            </>
          );
        }
      },
    },
  ];

  function TicketHolders() {
    let content: JSX.Element[] = [];

    for (let index = 1; index <= tixPurchaseQuantity; index++) {
      content.push(
        <div className={"purchase-modal-ticket-holders-container"}>
          <div>
            <h4>{index}. Ticket Holder</h4>
          </div>
          <div className={"purchase-modal-ticket-holders"}>
            <label>First Name : </label>
            <input
              onChange={(e) =>
                handleTixHolderFirstNameChange(index, e.target.value)
              }
            />
          </div>
          <div className={"purchase-modal-ticket-holders"}>
            <label>Last Name : </label>
            <input
              onChange={(e) =>
                handleTixHolderLastNameChange(index, e.target.value)
              }
            />
          </div>
          <div className={"purchase-modal-ticket-holders"}>
            <label>Email : </label>
            <input
              onChange={(e) =>
                handleTixHolderEmailChange(index, e.target.value)
              }
            />
          </div>
        </div>
      );
    }

    return <div>{content}</div>;
  }

  function handleTixHolderFirstNameChange(index: number, value: string) {
    let tixHolder = tixHolders[index];

    if (tixHolder == undefined) {
      tixHolder = {
        ...initialUser,
        profileType: 0,
      };
    }

    tixHolder.firstName = value;

    tixHolders[index] = tixHolder;

    setTixHolders(tixHolders);
  }

  function handleTixHolderLastNameChange(index: number, value: string) {
    let tixHolder = tixHolders[index];

    if (tixHolder == undefined) {
      tixHolder = {
        ...initialUser,
        profileType: 0,
      };
    }

    tixHolder.lastName = value;

    tixHolders[index] = tixHolder;

    setTixHolders(tixHolders);
  }

  function handleTixHolderEmailChange(index: number, value: string) {
    let tixHolder = tixHolders[index];

    if (tixHolder == undefined) {
      tixHolder = {
        ...initialUser,
        profileType: 0,
      };
    }

    tixHolder.email = value;

    tixHolders[index] = tixHolder;

    setTixHolders(tixHolders);
  }

  return (
    <div>
      <div className={"organization-container"}>
        <OrganizationOptions />
      </div>
      <div className={"team-container"} hidden={!isTeamsVisible}>
        <TeamOptions />
      </div>
      <div hidden={!isScheduleVisible}>
        <div hidden={true}>
          <button className={"btn"}>Purchase Season Tix</button>
        </div>
        <TicketsForSale />
      </div>
      <div>
        <Modal
          visible={purchaseModalVis}
          onCancel={() => setPurchaseModalVis(false)}
          onOk={() => {
            handlePurchase();
          }}
        >
          <div className={"purchase-modal-ticket-price"}>
            <label>First Name : </label>{" "}
            <input onChange={(e) => setPurchaseFirstName(e.target.value)} />
          </div>
          <div className={"purchase-modal-ticket-price"}>
            <label>Last Name : </label>{" "}
            <input onChange={(e) => setPurchaseLastName(e.target.value)} />
          </div>
          <div className={"purchase-modal-ticket-price"}>
            <label>Email : </label>{" "}
            <input onChange={(e) => setPurchaseEmail(e.target.value)} />
          </div>
          <div className={"purchase-modal-ticket-quantity"}>
            <label>Quantity : </label>
            <select
              value={tixPurchaseQuantity}
              onChange={(e) => setTixPurchaseQuantity(Number(e.target.value))}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
          <TicketHolders />
        </Modal>
      </div>
    </div>
  );
}
