import { unwrapResult } from "@reduxjs/toolkit";
import { notification, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import {
  getEventsScheduleDetailsAsync,
  updateEventAsync,
} from "../../redux/event/action";
import { IEventState } from "../../redux/event/type";
import {
  getSchedulesAsync,
  setSeasonPassAsync,
} from "../../redux/schedule/action";
import {
  initialSchedule,
  initialSeasonPassRequest,
} from "../../redux/schedule/reducer";
import { IScheduleState } from "../../redux/schedule/type";
import { SendTicketRequest, Ticket } from "../../redux/ticket/type";
import { Event } from "../../redux/event/type";
import NewTicket from "../ticket/newTicket";
import { IUserState, ProfileEnum } from "../../redux/user/type";
import { IUserAuthState } from "../../redux/auth/type";
import { initialEvent } from "../../redux/event/reducer";
import { sendTicketAsync } from "../../redux/ticket/action";

interface ScheduleDetails {
  key: number | string;
  name: string;
  maxTicketsPerIndividual: number;
  start: string;
  tickets: Array<{ name: string }>;
  isForPublicSale: boolean;
}

const data: ScheduleDetails[] = [];

export default function Schedule() {
  const dispatch: AppDispatch = useDispatch();
  const [eventScheduleId, setEventScheduleId] = useState(initialSchedule.id);
  const [inviteEvent, setInviteEvent] = useState(initialEvent);
  const [seasonPassRequest, setSeasonPassRequest] = useState(
    initialSeasonPassRequest
  );
  const [scheduleDetails, setScheduleDetails] = useState(data);
  const [isPlayer, setIsPlayer] = useState(true);

  const scheduleState: IScheduleState = useSelector(
    (state: AppState) => state.scheduleAsync
  );
  const eventState: IEventState = useSelector(
    (state: AppState) => state.eventAsync
  );
  const userAuthState: IUserAuthState = useSelector(
    (state: AppState) => state.userAuthSync
  );

  useEffect(() => {
    async function onLoad() {
      try {
        const result = await dispatch(getSchedulesAsync());

        unwrapResult(result);

        if (userAuthState.getMeResponse)
          setIsPlayer(
            userAuthState.getMeResponse.profiles[0].profileType ===
              ProfileEnum.PLAYER
          );

        notification.success({
          message: "Got Schedules",
        });
      } catch (error) {
        notification.error({
          message: "Failed To Get Schedules",
        });
      }
    }

    onLoad();
  }, [dispatch]);

  const [isModalVis, setIsModalVis] = useState(false);
  const [isPublicSaleModalVis, setIsPublicSaleModalVis] = useState(false);
  const [isSeasonPassModalVis, setIsSeasonPassModalVis] = useState(false);
  function expandedRow(record: ScheduleDetails) {
    let content: JSX.Element[] = [];

    record.tickets.forEach((invite) => {
      content.push(<li>{invite.name}</li>);
    });
    return <ol>{content}</ol>;
  }

  const columns = [
    { title: "Event Name", dataIndex: "name", key: "name" },
    { title: "Start Time", dataIndex: "start", key: "start" },
    {
      title: "Allotment",
      dataIndex: "maxTicketsPerIndividual",
      key: "maxTicketsPerIndividual",
    },
    {
      title: "Invites",
      dataIndex: "",
      key: "invites",
      render: (record: ScheduleDetails) => {
        if (record.tickets) return <span>{record.tickets.length}</span>;
      },
    },
    {
      title: "Add",
      dataIndex: "",
      key: "add",
      render: (record: ScheduleDetails) => {
        if (record.maxTicketsPerIndividual !== 0)
          return (
            <button
              className={"btn"}
              disabled={record.tickets.length >= record.maxTicketsPerIndividual}
              onClick={() => openSendTixModal(record)}
            >
              Send Tix
            </button>
          );
      },
    },
    {
      title: "Public Sale",
      dataIndex: "",
      key: "public sale",
      render: (record: ScheduleDetails) => {
        return (
          <>
            <input
              type={"checkbox"}
              disabled={isPlayer}
              defaultChecked={record.isForPublicSale}
              onClick={() => openPublicSaleModal(record)}
            />
          </>
        );
      },
    },
  ];

  function openSendTixModal(event: ScheduleDetails) {
    let eventFromApi = eventState.getEventScheduleDetailsResponse.events.find(
      (e) => e.id === event.key
    );

    if (!eventFromApi) return;

    setInviteEvent(eventFromApi);
    setIsModalVis(true);
  }

  function openPublicSaleModal(event: ScheduleDetails) {
    let eventFromApi = eventState.getEventScheduleDetailsResponse.events.find(
      (e) => e.id === event.key
    );

    if (!eventFromApi) return;

    setInviteEvent(eventFromApi);
    setIsPublicSaleModalVis(!event.isForPublicSale);

    //dispatch update event
  }

  function openSeasonPassModal() {
    setSeasonPassRequest({ ...seasonPassRequest, scheduleId: eventScheduleId });
    setIsSeasonPassModalVis(true);
  }

  function ScheduleDropdown() {
    let content: JSX.Element[] = [];

    content.push(<option value="">Select Schedule For Event</option>);
    if (scheduleState.getSchedulesResponse) {
      scheduleState.getSchedulesResponse.forEach((schedule, index) => {
        content.push(
          <option key={index} value={schedule.id}>
            {schedule.name}
          </option>
        );
      });
    }

    return (
      <select
        value={eventScheduleId}
        required
        onChange={(e) => {
          handleScheduleChange(e.target.value);
        }}
      >
        {content}
      </select>
    );
  }

  async function handleScheduleChange(scheduleId: string) {
    setEventScheduleId(scheduleId);

    if (!scheduleId) return;

    try {
      let details: Array<ScheduleDetails> = [];

      dispatch(getEventsScheduleDetailsAsync(scheduleId)).then((result) => {
        if (result.payload.events.length === 0) {
          setScheduleDetails([]);
        } else {
          result.payload.events.forEach((event: Event, index: number) => {
            let tix = getTicketsForEvent(result.payload.tickets, event.id).map(
              (tix) => {
                return { name: tix.guest.firstName };
              }
            );

            details.push({
              key: event.id,
              name: event.name,
              maxTicketsPerIndividual: event.maxTicketPerIndividual,
              start: moment(event.eventDate).format("llll"),
              tickets: tix,
              isForPublicSale: event.isForPublicSale,
            });
          });
          console.log("thing 3");

          setScheduleDetails(details);
        }
      });
    } catch (error) {}
  }

  function getTicketsForEvent(tix: Ticket[], eventId: string) {
    return tix.filter((ticket) => ticket.event.id === eventId);
  }

  function handleSendInvite(invite: SendTicketRequest) {
    invite = {
      ...invite,
      host: {
        firstName: userAuthState.getMeResponse.firstName,
        lastName: userAuthState.getMeResponse.lastName,
        email: userAuthState.getMeResponse.email,
        profileType: userAuthState.getMeResponse.profiles[0].profileType,
      },
      event: {
        id: inviteEvent.id,
        name: inviteEvent.name,
        ticketingManagerEmail: inviteEvent.ticketingManagerEmail,
        ticketingManagerName: inviteEvent.ticketingManagerName,
        location: inviteEvent.location,
      },
    };

    dispatch(sendTicketAsync(invite)).then((result) => {
      setIsModalVis(false);
    });
  }

  function handlePublicSaleChange(event: Event) {
    event.isForPublicSale = true;

    setIsModalVis(false);
    dispatch(updateEventAsync(event));
  }

  function handleSeasonPassChange() {
    dispatch(setSeasonPassAsync(seasonPassRequest));
  }

  return (
    <div>
      <h4>Schedule of Events</h4>
      <ScheduleDropdown />
      <div hidden={eventScheduleId == initialSchedule.id}>
        <button
          className={"btn"}
          disabled={isPlayer}
          onClick={() => openSeasonPassModal()}
        >
          Set Season Pass
        </button>
      </div>
      <Table
        columns={columns}
        dataSource={scheduleDetails}
        expandable={{
          expandedRowRender: (record) => expandedRow(record),
          rowExpandable: (record) => record.tickets.length !== 0,
        }}
      />
      <NewTicket
        onCancel={(vis: boolean) => setIsModalVis(vis)}
        visible={isModalVis}
        sendInvite={(inv: SendTicketRequest) => handleSendInvite(inv)}
      />
      <div>
        <Modal
          visible={isPublicSaleModalVis}
          onCancel={() => {
            setInviteEvent(initialEvent);
            setIsPublicSaleModalVis(false);
          }}
          onOk={() => {
            handlePublicSaleChange(inviteEvent);
            setIsPublicSaleModalVis(false);
          }}
        >
          <label>Price Per Ticket</label>
          <input
            type="text"
            onChange={(e) =>
              setInviteEvent({
                ...inviteEvent,
                publicSaleMeta: {
                  ...inviteEvent.publicSaleMeta,
                  pricePerTicket: e.target.value,
                },
              })
            }
          />
          <label>Max Public Ticket</label>
          <input
            type="text"
            onChange={(e) =>
              setInviteEvent({
                ...inviteEvent,
                publicSaleMeta: {
                  ...inviteEvent.publicSaleMeta,
                  maxTicketsForSale: Number(e.target.value),
                },
              })
            }
          />
        </Modal>
      </div>
      <div>
        <Modal
          visible={isSeasonPassModalVis}
          onCancel={() => {
            setInviteEvent(initialEvent);
            setIsSeasonPassModalVis(false);
          }}
          onOk={() => handleSeasonPassChange()}
        >
          <label>Price Per Ticket</label>
          <input
            type="text"
            onChange={(e) =>
              setSeasonPassRequest({
                ...seasonPassRequest,
                pricePerSeasonPass: e.target.value,
              })
            }
          />
          <label>Max Season Pass Tickets</label>
          <input
            type="text"
            onChange={(e) =>
              setSeasonPassRequest({
                ...seasonPassRequest,
                maxSeasonPassAvailable: e.target.value,
              })
            }
          />
        </Modal>
      </div>
    </div>
  );
}
