import { httpGetRequest, httpPostRequest } from "../../httpClient/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateRosterRequest, CreateUserRequest, User } from "./type";

export const createRosterAsync = createAsyncThunk(
  "user/createRosterAsync",
  async (request: CreateRosterRequest, { rejectWithValue }) => {
    try {
      let response: Array<User> = await httpPostRequest(
        "api/user/roster",
        request.users
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "user/createUserAsync",
  async (request: CreateUserRequest, { rejectWithValue }) => {
    try {
      let response: User = await httpPostRequest("api/user", request);
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createTixHolderAsync = createAsyncThunk(
  "user/createTixHolderAsync",
  async (request: CreateUserRequest, { rejectWithValue }) => {
    try {
      let response: User = await httpPostRequest(
        "api/user/tix-holder",
        request
      );
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getRosterAsync = createAsyncThunk(
  "user/getRosterAsync",
  async (teamId: string, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/user/list",
        params: teamId,
      });
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getMyTeamAsync = createAsyncThunk(
  "user/getMyTeamAsync",
  async (undefined, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/user/team/me",
      });
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getMeAsync = createAsyncThunk(
  "user/getMeAsync",
  async (undefined, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/user/me",
      });
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUserAsync",
  async (request: User, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/user/update", request);
      return response;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
