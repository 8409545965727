import React, { useEffect, useState } from "react";
import {
  HomeOutlined,
  InfoCircleOutlined,
  TeamOutlined,
  MailOutlined,
  LogoutOutlined,
  CaretDownOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  CalendarOutlined,
  ScheduleOutlined,
  CarryOutOutlined,
  PlusSquareOutlined,
  BarsOutlined,
  SafetyOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import TixLogo from "../../images/Tix Logo only.png";
import "./sideNav.css";
import { IUserAuthState } from "../../redux/auth/type";
import { useSelector } from "react-redux";
import { AppState } from "../../redux";
import { ProfileEnum } from "../../redux/user/type";

export default function SideNav() {
  const loginStatus: IUserAuthState = useSelector(
    (state: AppState) => state.userAuthSync
  );

  const [showEventsDropdown, setShowEventsDropdown] = useState(false);
  const [showTeamDropdown, setShowTeamDropdown] = useState(false);
  const [showTicketDropdown, setShowTicketDropdown] = useState(false);
  const [isTixHolder, setIsTixHolder] = useState(true);
  const [canAddUsers, setCanAddUsers] = useState(false);
  const [canAddEvents, setCanAddEvents] = useState(false);
  const [canViewEventDetails, setCanViewEventDetails] = useState(false);
  const [isMemberOfOrg, setIsMemberOfOrg] = useState(false);

  useEffect(() => {
    if (loginStatus.getMeResponse) {
      setIsTixHolder(
        loginStatus.getMeResponse.profiles[0].profileType ===
          ProfileEnum.TICKETHOLDER
      );

      setCanAddUsers(() => {
        switch (loginStatus.getMeResponse.profiles[0].profileType) {
          case ProfileEnum.ADMIN:
            return true;
          case ProfileEnum.COACH:
            return true;
          case ProfileEnum.PLAYER:
            return false;
          case ProfileEnum.STAFF:
            return false;
          case ProfileEnum.TEAMMANAGER:
            return true;
          case ProfileEnum.TICKETHOLDER:
            return false;
          case ProfileEnum.TICKETINGMANAGER:
            return false;
          default:
            return false;
        }
      });

      setCanAddEvents(() => {
        switch (loginStatus.getMeResponse.profiles[0].profileType) {
          case ProfileEnum.ADMIN:
            return true;
          case ProfileEnum.COACH:
            return true;
          case ProfileEnum.PLAYER:
            return false;
          case ProfileEnum.STAFF:
            return false;
          case ProfileEnum.TEAMMANAGER:
            return true;
          case ProfileEnum.TICKETHOLDER:
            return false;
          case ProfileEnum.TICKETINGMANAGER:
            return true;
          default:
            return false;
        }
      });

      setCanViewEventDetails(() => {
        switch (loginStatus.getMeResponse.profiles[0].profileType) {
          case ProfileEnum.ADMIN:
            return true;
          case ProfileEnum.COACH:
            return true;
          case ProfileEnum.PLAYER:
            return false;
          case ProfileEnum.STAFF:
            return false;
          case ProfileEnum.TEAMMANAGER:
            return false;
          case ProfileEnum.TICKETHOLDER:
            return false;
          case ProfileEnum.TICKETINGMANAGER:
            return true;
          default:
            return false;
        }
      });

      setIsMemberOfOrg(() => {
        switch (loginStatus.getMeResponse.profiles[0].profileType) {
          case ProfileEnum.ADMIN:
            return true;
          case ProfileEnum.COACH:
            return true;
          case ProfileEnum.PLAYER:
            return true;
          case ProfileEnum.STAFF:
            return true;
          case ProfileEnum.TEAMMANAGER:
            return true;
          case ProfileEnum.TICKETHOLDER:
            return false;
          case ProfileEnum.TICKETINGMANAGER:
            return true;
          default:
            return false;
        }
      });
    }
  }, []);

  function closeNav() {
    if (document.getElementById("mySidenav"))
      document.getElementById("mySidenav")!.style.width = "0";
  }

  let content = <></>;
  if (loginStatus.loggedIn && loginStatus.getMeResponse) {
    content = (
      <>
        <button
          onClick={() => setShowEventsDropdown(!showEventsDropdown)}
          hidden={!isMemberOfOrg}
          className="dropdown-btn"
        >
          <CalendarOutlined /> Events
          <CaretDownOutlined className={"fa-caret-down"} />
        </button>
        <div
          className="dropdown-container"
          style={{ display: showEventsDropdown ? "block" : "none" }}
        >
          <a href="/new-schedule" hidden={!canAddEvents}>
            <PlusSquareOutlined /> New Schedule
          </a>
          <a href="/upload-schedule" hidden={!canAddEvents}>
            <ScheduleOutlined /> Upload Event(s)
          </a>
          <a href="/new-event" hidden={!canAddEvents}>
            <CarryOutOutlined /> Add Event
          </a>
          <a href="/schedule" hidden={!isMemberOfOrg}>
            <BarsOutlined /> View Schedule
          </a>
        </div>
        <button
          onClick={() => setShowTicketDropdown(!showTicketDropdown)}
          className="dropdown-btn"
        >
          <img src={TixLogo} alt={"tixLogo"} className="biz-icon" /> Tix
          <CaretDownOutlined className={"fa-caret-down"} />
        </button>
        <div
          className="dropdown-container"
          style={{ display: showTicketDropdown ? "block" : "none" }}
        >
          <a href="/tickets-list" hidden={!canViewEventDetails}>
            <UnorderedListOutlined /> View Tickets Per Event
          </a>
          <a href="/tickets-list/me">
            <UnorderedListOutlined /> My Tix
          </a>
        </div>
        <button
          onClick={() => setShowTeamDropdown(!showTeamDropdown)}
          hidden={!isMemberOfOrg}
          className="dropdown-btn"
        >
          <TeamOutlined /> Team
          <CaretDownOutlined className={"fa-caret-down"} />
        </button>
        <div
          className="dropdown-container"
          style={{ display: showTeamDropdown ? "block" : "none" }}
        >
          <a href="/upload-team" hidden={!canAddUsers}>
            <UsergroupAddOutlined /> Upload User(s)
          </a>
          <a href="/new-user" hidden={!canAddUsers}>
            <UserAddOutlined /> Add User
          </a>
          <a href="/users" hidden={!isMemberOfOrg}>
            <BarsOutlined /> View Users
          </a>
        </div>
        <a href="/profile">
          <img src={TixLogo} alt={"tixLogo"} className="biz-icon" />
          Welcome{" "}
          {loginStatus.getMeResponse.firstName === undefined
            ? ""
            : loginStatus.getMeResponse.firstName}
        </a>
        <a
          href={"/"}
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          <LogoutOutlined />
          Logout
        </a>
      </>
    );
  } else {
    content = (
      <>
        <a href="/organization-registration">
          <TeamOutlined /> Add Your Team to We'll Call Tix
        </a>
        <a href="/tix-holder-registration">
          <UserOutlined /> Join We'll Call Tix Yourself
        </a>
        <a href="/login">
          <img src={TixLogo} alt={"tixLogo"} className="biz-icon" />
          Login
        </a>
      </>
    );
  }

  return (
    <div id="mySidenav" className="sidenav">
      <div className="closebtn" onClick={closeNav}>
        &times;
      </div>
      <a href="/">
        <HomeOutlined /> Home
      </a>
      <a href="/about">
        <InfoCircleOutlined /> About
      </a>
      <a href="/contact">
        <MailOutlined /> Contact Us
      </a>
      <a href="/valid-ticket">
        <SafetyOutlined /> Validate Ticket
      </a>
      <a href="/purchase-ticket">
        <ShoppingOutlined /> Purchase Ticket(s)
      </a>
      {content}
    </div>
  );
}
