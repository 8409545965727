import React, { useEffect, useState } from "react";
import { CSVReader, CSVDownloader } from "react-papaparse";
import { notification, Table } from "antd";
import { CreateRosterRequest, ProfileEnum } from "../../redux/user/type";
import { initialCreateRosterRequest } from "../../redux/user/reducer";
import { AppDispatch, AppState } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { createRosterAsync } from "../../redux/user/action";
import { unwrapResult } from "@reduxjs/toolkit";
import { getSchedulesAsync } from "../../redux/schedule/action";
import { IScheduleState } from "../../redux/schedule/type";

export interface CSVFile {
  data: string[];
  errors: string[];
  meta: {
    delimeter: string;
    linebreak: string;
    aborted: boolean;
    truncated: boolean;
    cursor: number;
  };
}

interface CSVRow {
  key: number;
  first: string;
  last: string;
  email: string;
  profile: string;
}

export default function UploadTeam() {
  const dispatch: AppDispatch = useDispatch();
  const initTableData: CSVRow[] = [];
  const [tableData, setTableData] = useState(initTableData);
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [rosterRequest, setRosterRequest] = useState(
    initialCreateRosterRequest
  );

  const tableColumns = [
    {
      title: "First",
      dataIndex: "first",
      key: "1",
    },
    {
      title: "Last",
      dataIndex: "last",
      key: "2",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "3",
    },
    {
      title: "Profile Type",
      dataIndex: "profile",
      key: "4",
    },
  ];

  function handleOnDrop(data: CSVFile[], file: any) {
    const headerValues: string[] = Array.from(tableColumns, (tc) =>
      tc.dataIndex.toLowerCase()
    );
    let header = data[0];

    try {
      let csvHeaders = Array.from(header.data, (hd) =>
        hd.replace(/\s/g, "").toLowerCase()
      );

      headerValues.forEach((element) => {
        if (csvHeaders.indexOf(element) === -1) {
          throw new Error(`${element} not found in header`);
        }
      });
    } catch {
      notification.error({
        message: "Invalid Header",
        description: `Please check your header`,
      });
      return;
    }

    let csvRecords = data.slice(1, data.length);
    let tableRows: CSVRow[] = parseCsvRecords(csvRecords, headerValues.length);

    setTableData(tableRows);

    notification.success({
      message: "Successful Upload",
      description: "Roster Successfully Uploaded",
    });

    setRequest(tableRows);
    setUploadDisabled(false);
  }

  function setRequest(tableData: CSVRow[]) {
    let rosterReq: CreateRosterRequest = initialCreateRosterRequest;

    tableData.forEach((user) => {
      rosterReq.users.push({
        firstName: user.first,
        lastName: user.last,
        email: user.email,
        profileType: ProfileEnum[user.profile as keyof typeof ProfileEnum],
      });
    });

    setRosterRequest(rosterReq);
  }

  function parseCsvRecords(csvBody: CSVFile[], headerLength: number) {
    let tableRows: CSVRow[] = [];

    try {
      csvBody.forEach((line, index) => {
        if (line.data.length !== headerLength) {
          throw new Error(`Error Processing Row ${index}`);
        }

        var tableRow: CSVRow = {
          key: index,
          first: line.data[0],
          last: line.data[1],
          email: line.data[2],
          profile: line.data[3],
        };

        tableRows.push(tableRow);
      });
    } catch {
      notification.error({
        message: "Invalid Row",
        description: `Error Parsing Rows of CSV`,
      });
      return tableRows;
    }

    return tableRows;
  }

  function handleOnError(err: any, file: any, inputElem: any, reason: any) {
    notification.error({
      message: "CSV Upload Error",
      description: `Error Uploading CSV. Please Try Again.`,
    });
  }

  const handleUpload = async () => {
    try {
      const result = await dispatch(createRosterAsync(rosterRequest));

      unwrapResult(result);

      console.log(result);

      notification.success({
        message: "Roster Created",
        description: "Successfully created roster",
      });
    } catch (error) {
      notification.error({
        message: "Roster Failed To Create",
        description:
          "There was an error creating your roster. Please Try Again.",
      });
    }
  };

  return (
    <div className="upload-team">
      <h4>Upload Multiple Users</h4>
      <div className={"col-33"}>
        <CSVDownloader
          className={"btn"}
          data={[
            {
              First: "John",
              Last: "Doe",
              Email: "john.doe@email.com",
              Profile: "COACH",
            },
          ]}
          type={"button"}
          filename={"empty-upload-team"}
          bom={true}
        >
          Download Template
        </CSVDownloader>
        <CSVReader
          style={{ height: "auto" }}
          onDrop={(data: any, file?: any) => handleOnDrop(data, file)}
          onError={handleOnError}
        ></CSVReader>
      </div>
      <Table columns={tableColumns} dataSource={tableData} />

      <div className="col-33">
        <button
          disabled={uploadDisabled}
          className="btn"
          type="submit"
          onClick={() => handleUpload()}
        >
          Upload
        </button>
      </div>
    </div>
  );
}
