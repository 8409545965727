import { Card } from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import React, { useEffect } from "react";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import { getMyTixAsync } from "../../redux/ticket/action";
import { ITicketState } from "../../redux/ticket/type";
import "./myTix.css";

export default function MyTix() {
  const dispatch: AppDispatch = useDispatch();

  const tixState: ITicketState = useSelector(
    (state: AppState) => state.ticketAsync
  );

  useEffect(() => {
    dispatch(getMyTixAsync());
  }, []);

  function MyTixContainer() {
    let content: JSX.Element[] = [];

    if (tixState.getMyTixResponse)
      tixState.getMyTixResponse.forEach((tix) => {
        let meta: JSX.Element = (
          <div>
            <label className={"tix-label"}>
              {moment(tix.event.eventDate).format("llll")}
            </label>
            <label className={"tix-label"}>
              {tix.guest.firstName} {tix.guest.lastName}
            </label>
            {/* <label className={"tix-label"}>
              {TicketType}
            </label> */}
          </div>
        );

        content.push(
          <div className={"tix-card-container"}>
            <Card
              style={{ margin: "0px" }}
              cover={
                <QRCode
                  bgColor={"#7bc344"}
                  fgColor={"#1c2c5c"}
                  style={{ margin: "0px" }}
                  value={JSON.stringify({ id: tix.id })}
                />
              }
            >
              <Meta
                className={"tix-card-meta"}
                title={tix.event.name}
                description={meta}
              />
            </Card>
          </div>
        );
      });

    return <div>{content}</div>;
  }

  return (
    <div className={"my-tix-container"}>
      <MyTixContainer />
    </div>
  );
}
