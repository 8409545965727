import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  createRosterAsync,
  createTixHolderAsync,
  createUserAsync,
  getMeAsync,
  getMyTeamAsync,
} from "./action";
import {
  CreateRosterRequest,
  CreateRosterResponse,
  CreateUserRequest,
  GetMeResponse,
  IUserState,
  User,
} from "./type";

export let initialUser: User = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  profiles: [],
};

export let initialCreateUserRequest: CreateUserRequest = {
  firstName: "",
  lastName: "",
  email: "",
  profileType: 0,
};

export let initialCreateRosterRequest: CreateRosterRequest = {
  users: [],
};

export let initialCreateRosterResponse: CreateRosterResponse = {
  users: [],
};

export const initialState: IUserState = {
  isLoading: false,
  error: "",
  createUserRequest: initialCreateUserRequest,
  createUserResponse: initialUser,
  createRosterRequest: initialCreateRosterRequest,
  createRosterResponse: initialCreateRosterResponse,
  getMyTeamResponse: [],
  getMeResponse: { ...initialUser, organizations: [], teams: [] },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [createRosterAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<User>>
    ) => {
      state.createRosterResponse.users = action.payload;
    },
    [createUserAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<User>
    ) => {
      state.createUserResponse = action.payload;
      state.isLoading = false;
    },
    [createTixHolderAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<User>
    ) => {
      state.createUserResponse = action.payload;
      state.isLoading = false;
    },
    [createUserAsync.rejected.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    [createUserAsync.pending.toString()]: (state) => {
      state.isLoading = true;
    },
    [getMyTeamAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<User>>
    ) => {
      state.getMyTeamResponse = action.payload;
    },
    [getMeAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<GetMeResponse>
    ) => {
      state.getMeResponse = action.payload;
    },
  },
});

export default userSlice.reducer;
