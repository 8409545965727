import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./main/home/home";
import About from "./main/about/about";
import SideNav from "./main/side-nav/sideNav";
import logo from "./images/logo.png";
import "antd/dist/antd.css";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import UploadTeam from "./main/user/uploadTeam";
import NewUser from "./main/user/newUser";
import ViewUsers from "./main/user/users";
import Profile from "./main/user/profile";
import UploadSchedule from "./main/event/uploadSchedule";
import NewSchedule from "./main/event/newSchedule";
import Schedule from "./main/event/schedule";
import NewEvent from "./main/event/newEvent";
import EditEvents from "./main/event/editEvents";
import PurchaseTicket from "./main/ticket/purchaseTicket";
import Checkout from "./main/ticket/checkout";
import ValidTicket from "./main/ticket/validTicket";
import MyTix from "./main/ticket/myTix";
import TicketsList from "./main/ticket/ticketsList";
import OrganizationRegistration from "./main/registration/organization-registration";
import TixHolderRegistration from "./main/registration/tix-holder-registration";
import Login from "./main/login/login";
import ResetPassword from "./main/login/reset-password";
import { AppDispatch, AppState } from "./redux";
import { useDispatch, useSelector } from "react-redux";
import Contact from "./main/contact/contact";
import { getMyAuthAsync } from "./redux/auth/action";
import { notification } from "antd";
import { unwrapResult } from "@reduxjs/toolkit";
import { IUserAuthState } from "./redux/auth/type";
import { IUserState, ProfileEnum } from "./redux/user/type";

function App() {
  const loginStatus: IUserAuthState = useSelector(
    (state: AppState) => state.userAuthSync
  );
  const dispatch: AppDispatch = useDispatch();
  const [isPlayer, setIsPlayer] = useState(true);

  useEffect(() => {
    async function AuthCheck() {
      if (loginStatus.loggedIn) {
        try {
          if (!localStorage.getItem("AUTH_TOKEN")) {
            throw "";
          }

          var result = await dispatch(getMyAuthAsync());

          unwrapResult(result);

          if (loginStatus.getMeResponse)
            setIsPlayer(
              loginStatus.getMeResponse.profiles[0].profileType ===
                ProfileEnum.PLAYER
            );
        } catch (error) {
          localStorage.clear();

          notification.error({
            message: "Token Expired",
            description: "Time To Log Back In",
          });
          window.location.assign("/login");
        }
      }
    }
    AuthCheck();
  }, [dispatch, loginStatus.userLoginResponse]);

  return (
    <div className="App">
      <SideNav />
      <div className={"banner"}>
        <img className={"banner-img"} src={logo} />
      </div>
      <header className="App-header">
        <div className="col-33 menu-btn" onClick={() => openNav()}>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
        </div>
      </header>

      <div className="App-main">
        <Router>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/upload-team">
              {loginStatus.loggedIn && !isPlayer && <UploadTeam />}
            </Route>
            <Route path="/new-user">
              {loginStatus.loggedIn && !isPlayer && <NewUser />}
            </Route>
            <Route path="/profile">{loginStatus.loggedIn && <Profile />}</Route>
            <Route path="/users">{loginStatus.loggedIn && <ViewUsers />}</Route>
            <Route path="/upload-schedule">
              {loginStatus.loggedIn && !isPlayer && <UploadSchedule />}
            </Route>
            <Route path="/new-event">
              {loginStatus.loggedIn && !isPlayer && <NewEvent />}
            </Route>
            <Route path="/edit-events">
              {loginStatus.loggedIn && !isPlayer && <EditEvents />}
            </Route>
            <Route path="/new-schedule">
              {loginStatus.loggedIn && !isPlayer && <NewSchedule />}
            </Route>
            <Route path="/tickets-list/me">
              {loginStatus.loggedIn && <MyTix />}
            </Route>
            <Route path="/tickets-list">
              {loginStatus.loggedIn && !isPlayer && (
                <TicketsList
                  teamId={loginStatus.getMeResponse.profiles[0].teamId}
                />
              )}
            </Route>
            <Route path="/schedule">
              {loginStatus.loggedIn && <Schedule />}
            </Route>
            <Route path="/valid-ticket">{<ValidTicket />}</Route>
            <Route path="/purchase-ticket">{<PurchaseTicket />}</Route>
            <Route path="/checkout/:client_secret" component={Checkout} />
            <Route path="/organization-registration">
              <OrganizationRegistration />
            </Route>
            <Route path="/tix-holder-registration">
              <TixHolderRegistration />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/reset-password/:token" component={ResetPassword} />
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>

      <footer className="App-footer">
        <div className="col-33">
          <a href={"mailto:admin@wellcall.biz"}>
            <MailOutlined />
          </a>
        </div>
        <div className="col-33">
          <button
            className="btn"
            type="submit"
            onClick={() =>
              window.open(
                "https://www.facebook.com/Well-Call-Community-102031021904914",
                "_blank"
              )
            }
          >
            Follow Us On Facebook
          </button>
        </div>
        <div className="col-33">
          <a href={"tel:559-574-1056"}>
            <PhoneOutlined />
          </a>
        </div>
      </footer>
    </div>
  );
}

/* Set the width of the side navigation to 250px */
function openNav() {
  if (document.getElementById("mySidenav")) {
    document.getElementById("mySidenav")!.style.width = "250px";
  }
}

export default App;
