import { httpGetRequest, httpPostRequest } from "../../httpClient/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { PurchaseTixRequest, SendTicketRequest } from "./type";

export const sendTicketAsync = createAsyncThunk(
  "ticket/sendTicketAsync",
  async (request: SendTicketRequest, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/ticket", request);
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const purchaseTixAsync = createAsyncThunk(
  "ticket/purchaseTixAsync",
  async (request: PurchaseTixRequest, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/ticket/purchase", request);
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const purchaseTixCompleteAsync = createAsyncThunk(
  "ticket/purchaseTixCompleteAsync",
  async (request: string, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/ticket/purchase/complete", {
        paymentIntent: request,
      });
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const validTicketAsync = createAsyncThunk(
  "ticket/validTicketAsync",
  async (ticketId: string, { rejectWithValue }) => {
    let response = await httpGetRequest({
      url: "api/ticket/valid",
      params: { ticketId: ticketId },
    });
    return response;
  }
);

export const getTixForEventAsync = createAsyncThunk(
  "ticket/getTixForEventAsync",
  async (eventId: string, { rejectWithValue }) => {
    let response = await httpGetRequest({
      url: "api/ticket/list/event",
      params: { eventId: eventId },
    });
    return response;
  }
);

export const getMyTixAsync = createAsyncThunk(
  "ticket/getMyTixAsync",
  async (undefined, { rejectWithValue }) => {
    let response = await httpGetRequest({
      url: "api/ticket/list/me",
    });
    return response;
  }
);
