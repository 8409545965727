import React, { useEffect, useState } from "react";
import StripeCheckout from "react-stripe-checkout";
import { loadStripe, PaymentRequestOptions } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { ITicketState } from "../../redux/ticket/type";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState, rootReducer } from "../../redux";
import "./checkout.css";
import { purchaseTixCompleteAsync } from "../../redux/ticket/action";
import { notification } from "antd";
import { useHistory } from "react-router";

export interface CheckoutFormParams {
  client_secret: string;
}

export default function CheckoutForm(params: CheckoutFormParams) {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [total, setTotal] = useState(0);
  const [clientSecret, setClientSecret] = useState(params.client_secret);
  const [paymentRequest, setPaymentRequest] = useState<any>(null);
  const stripe = useStripe();
  const elements = useElements();

  const tixState: ITicketState = useSelector(
    (state: AppState) => state.ticketAsync
  );

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: tixState.purchaseTixResponse.purchaseQuantity + " ticket(s)",
          amount: total,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      console.log("pr");
      console.log(pr);

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        console.log("result");
        console.log(result);
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", async (ev: any) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const {
        paymentIntent,
        error: confirmError,
      } = await stripe!.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.

        notification.error({
          message: "Payment Failed",
          description: "Payment Failed",
          duration: 10,
        });

        ev.complete("fail");
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.

        notification.success({
          message: "Payment Successful",
          description: "Please check your email for your tix.",
        });

        ev.complete("success");
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent!.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe!.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.

            notification.error({
              message: "Payment Failed",
              description: "Payment Failed",
              duration: 10,
            });
          } else {
            // The payment has succeeded.

            notification.success({
              message: "Payment Successful",
              description: "Please check your email for your tix.",
            });
          }
        } else {
          // The payment has succeeded.

          notification.success({
            message: "Payment Successful",
            description: "Please check your email for your tix.",
          });
        }
      }
    });
  }

  function PaymentContainer() {
    if (paymentRequest) {
      return <PaymentRequestButtonElement options={{ paymentRequest }} />;
    } else {
      return (
        <CardElement
          id="card-element"
          options={cardStyle}
          onChange={(e) => handleChange(e)}
        />
      );
    }
  }

  async function handleChange(event: any) {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  }

  async function handleSubmit() {
    let payload: any = {};
    // ev.preventDefault();
    setProcessing(true);
    if (stripe && elements) {
      payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
        },
      });
    }

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);

      notification.error({
        message: "Payment Failed",
        description: payload.error.message,
        duration: 10,
      });
    } else {
      setError("");
      setProcessing(false);
      setSucceeded(true);
      dispatch(purchaseTixCompleteAsync(params.client_secret));

      notification.success({
        message: "Payment Successful",
        description: "Please check your email for your tix.",
      });

      history.push("/");
    }
  }

  const cardStyle = {
    style: {
      base: {
        color: "#7bc344",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#1c2c5c",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  interface ConvienceFeeProps {
    subtotal: string;
  }

  function ConvienceFee() {
    let sub = Number(tixState.purchaseTixResponse.subtotal);
    let fee: number = 0;

    if (sub > 0) {
      fee = Math.round(sub * 0.07 * 100) / 100;

      setTotal(fee + sub);
    }

    return <label>{fee}</label>;
  }

  return (
    <div className="payment-form-container">
      <form id="payment-form">
        <div className={"purchase-modal-ticket-price"}>
          <label>Price Per Tix : </label>
          <label>$ {tixState.purchaseTixResponse.price}</label>
        </div>
        <div className={"purchase-modal-ticket-price"}>
          <label>Number of Tix : </label>
          <label>{tixState.purchaseTixResponse.purchaseQuantity}</label>
        </div>
        <div className={"purchase-modal-ticket-price"}>
          <label>Subtotal : </label>
          <label> $ {tixState.purchaseTixResponse.subtotal}</label>
        </div>
        <div className={"purchase-modal-ticket-price"}>
          <label>Convience Fee (7%) : </label>
          <ConvienceFee />
        </div>
        <div className={"purchase-modal-ticket-price"}>
          <label>Total : </label>
          <label>$ {total}</label>
        </div>
        <PaymentContainer />
        {/* Show any error that happens when processing the payment */}
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        {/* Show a success message upon completion */}
      </form>
      <button
        className={"btn"}
        disabled={processing || disabled || succeeded}
        id="submit"
        onClick={() => handleSubmit()}
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>
    </div>
  );
}
