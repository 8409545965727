import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getMyAuthAsync,
  passwordResetAsync,
  resetPasswordAsync,
  switchProfileAsync,
  userLoginAsync,
} from "./action";
import { IUserAuthState, LoginRequest, UserAuth } from "./type";

export let initialUserAuth: UserAuth = {
  password: "",
  username: "",
  email: "",
  firstName: "",
  lastName: "",
  profiles: [{ profileType: 0, teamId: "", id: "" }],
};

export let initialLoginRequest: LoginRequest = {
  password: "",
  username: "",
};

export const initialState: IUserAuthState = {
  isLoading: false,
  loggedIn: false,
  userAuthError: false,
  userLoginResponse: "",
  error: undefined,
  initialUserAuth: initialUserAuth,
  getMeResponse: initialUserAuth,
  userLoginRequest: initialLoginRequest,
  resetPasswordSuccess: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
  extraReducers: {
    [userLoginAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.userLoginResponse = action.payload;
      state.loggedIn = true;
    },
    [userLoginAsync.rejected.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.error = action.payload;
      state.loggedIn = false;
    },
    [getMyAuthAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<UserAuth>
    ) => {
      state.getMeResponse = action.payload;
    },
    [resetPasswordAsync.fulfilled.toString()]: (state) => {
      state.resetPasswordSuccess = true;
    },
    [resetPasswordAsync.rejected.toString()]: (state) => {
      state.resetPasswordSuccess = false;
    },
    [getMyAuthAsync.rejected.toString()]: (state) => {
      state = initialState;
    },
    [switchProfileAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.userLoginResponse = action.payload;
    },
  },
});

export default loginSlice.reducer;
