import { Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import { IUserAuthState } from "../../redux/auth/type";
import {
  getEventsByTeamIdAsync,
  getEventsForPublicSaleAsync,
} from "../../redux/event/action";
import { IEventState } from "../../redux/event/type";
import { getOrgsAsync } from "../../redux/organization/action";
import { IOrganizationState } from "../../redux/organization/type";
import { getTeamsForOrganizationAsync } from "../../redux/team/action";
import { ITeamState, TeamType } from "../../redux/team/type";
import { getTixForEventAsync } from "../../redux/ticket/action";
import { ITicketState } from "../../redux/ticket/type";
import { IUserState } from "../../redux/user/type";

interface TicketListProps {
  teamId: string;
}

export default function TicketsList(props: TicketListProps) {
  const [purchaseOrganization, setPurchaseOrganization] = useState("");
  const [purchaseTeam, setPurchaseTeam] = useState("");
  const [isTeamsVisible, setIsTeamsVisible] = useState(false);
  const [isScheduleVisible, setIsScheduleVisible] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const eventState: IEventState = useSelector(
    (state: AppState) => state.eventAsync
  );

  const tixState: ITicketState = useSelector(
    (state: AppState) => state.ticketAsync
  );

  useEffect(() => {
    dispatch(getEventsByTeamIdAsync(props.teamId));
  }, []);

  function EventOptions() {
    let content: JSX.Element[] = [];

    content.push(<option value="">Select An Organization</option>);
    if (eventState.getEventsByTeamResponse) {
      eventState.getEventsByTeamResponse.forEach((event, index) => {
        content.push(
          <option key={index} value={event.id}>
            {event.name}
          </option>
        );
      });
    }

    return (
      <select
        required
        value={purchaseOrganization}
        onChange={(e) => handleEventSelection(e.target.value)}
      >
        {content}
      </select>
    );
  }

  const columns = [
    { title: "First Name", dataIndex: "firstName", key: "firstName" },
    { title: "Last Name", dataIndex: "lastName", key: "lastName" },
    { title: "Ticket Type", dataIndex: "type", key: "type" },
  ];

  function TixForEvent() {
    let content: any[] = [];

    if (tixState.getTixForEventResponse) {
      tixState.getTixForEventResponse.forEach((tix, index) => {
        content.push({
          firstName: tix.guest.firstName,
          lastName: tix.guest.lastName,
          type: tix.ticketType,
        });
      });
    }

    return <Table columns={columns} dataSource={content} />;
  }

  function handleEventSelection(eventId: any) {
    setPurchaseOrganization(eventId);
    dispatch(getTixForEventAsync(eventId));
    setIsTeamsVisible(true);
  }

  return (
    <div>
      <EventOptions />
      <TixForEvent />
    </div>
  );
}
