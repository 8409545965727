import { unwrapResult } from "@reduxjs/toolkit";
import { Col, notification, Row, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { AppDispatch } from "../../redux";
import { createOrgAsync } from "../../redux/organization/action";
import { initialCreateOrgRequest } from "../../redux/organization/reducer";
import { OrganizationType } from "../../redux/organization/type";
import { ProfileEnum } from "../../redux/user/type";
import "./registration.css";

export default function OrganizationRegistration() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();

  interface TeamRowProps {
    index: number;
  }

  interface Team {
    type: number;
    tixMgr: string;
    teamMgr: string;
  }

  let teamToAdd: Team = {
    type: 1,
    tixMgr: "",
    teamMgr: "",
  };

  const initTeams: Array<Team> = [];

  const [teams, setTeams] = useState(initTeams);
  const [request, setRequest] = useState(initialCreateOrgRequest);
  const [modalOpen, setModalOpen] = useState(false);
  const [teamCount, setTeamCount] = useState(1);

  async function handleFormSubmit() {
    try {
      const result = await dispatch(createOrgAsync(request));

      unwrapResult(result);

      notification.success({
        message: "Successfully Created Organization",
        description: "Successfully created organization. Thank you.",
      });
      history.push("/");
    } catch (error) {
      notification.error({
        message: "Failed To Create Organization",
        description: "Failed to create organization. Please try again",
      });
    }
  }

  const TeamRow = (props: TeamRowProps) => {
    return (
      <div className={"team-row-registration"}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={8}
          xxl={8}
          className={"team-row-attribute"}
        >
          <label>Team Type</label>
          <select>
            <option>1</option>
            <option>2</option>
            <option>3</option>
          </select>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={8}
          xxl={8}
          className={"team-row-attribute"}
        >
          <label>Tix Manager</label>
          <input
            onChange={(e) =>
              handleTeamTixMgrChange(props.index, e.target.value)
            }
          ></input>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={8}
          xl={8}
          xxl={8}
          className={"team-row-attribute"}
        >
          <label>Team Manager</label>
          <input
            onChange={(e) => handleTeamMgrChange(props.index, e.target.value)}
          ></input>
        </Col>
      </div>
    );
  };

  const handleTeamTypeChange = (index: number, value: string) => {
    let teamToUpdate = teams[index];

    teamToUpdate.tixMgr = value;

    teams[index] = teamToUpdate;

    setTeams(teams);
  };

  const handleTeamTixMgrChange = (index: number, value: string) => {
    let teamToUpdate = teams[index];

    teamToUpdate.tixMgr = value;

    teams[index] = teamToUpdate;

    setTeams(teams);
  };

  const handleTeamMgrChange = (index: number, value: string) => {
    let teamToUpdate = teams[index];

    teamToUpdate.tixMgr = value;

    teams[index] = teamToUpdate;

    setTeams(teams);
  };

  const [teamRows, setTeamRows] = useState([<TeamRow index={1} />]);

  const handleAddTeamRow = () => {
    let newTeams = teamCount + 1;

    let somethingElse = <TeamRow index={newTeams} />;

    setTeamRows([...teamRows, somethingElse]);
    setTeamCount(newTeams);
  };

  return (
    <div>
      <div>
        <p>
          Thank you for your interest in We'll Call. As you may already know,
          getting started is fairly simple. First Registration, which will
          deliver emails to the admin and ticketing manager so they can confirm
          their account and reset their passwords. Following completion of
          resetting the password, each user will receive a notification that the
          password has successfully been reset. With accounts confirmed, the
          admin will be able to begin adding events and more users. Once users
          confirm their account just like the admin, they will be allowed to
          invite guest to event(s). And thats all she wrote.
        </p>
      </div>
      <form className="registration-form" onSubmit={() => handleFormSubmit()}>
        <div>
          <div className="form-section">
            <h4>Organization</h4>
            <label>Organization Name</label>
            <input
              type="text"
              id="orgName"
              className="registration-textbox"
              placeholder="Murray State University"
              required
              onChange={(e) => setRequest({ ...request, name: e.target.value })}
            />
            <label>Organization Phone</label>
            <input
              type="tel"
              id="orgPhone"
              className="registration-textbox"
              placeholder="222-333-4444"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required
              onChange={(e) =>
                setRequest({ ...request, phone: e.target.value })
              }
            />
            <label>Organization Type</label>
            <select
              required
              onChange={(e) =>
                setRequest({
                  ...request,
                  type:
                    OrganizationType[
                      e.target.value as keyof typeof OrganizationType
                    ],
                })
              }
            >
              <option>Select A Profile</option>
              <option value="1">SCHOOL</option>
              <option value="2">BUSINESS</option>
              <option value="3">PERSONAL</option>
            </select>
            <div>
              <div>
                <label>Street</label>
                <input
                  type="text"
                  id="teamPhone"
                  className="registration-textbox"
                  placeholder="1 Way Dr."
                  required
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      address: { ...request.address, address1: e.target.value },
                    })
                  }
                />
              </div>
              <div>
                <label>Street</label>
                <input
                  type="text"
                  id="teamPhone"
                  className="registration-textbox"
                  placeholder="Optional"
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      address: { ...request.address, address2: e.target.value },
                    })
                  }
                />
              </div>
              <div>
                <label>City</label>
                <input
                  type="text"
                  id="teamPhone"
                  className="registration-textbox"
                  placeholder="Murray"
                  required
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      address: { ...request.address, city: e.target.value },
                    })
                  }
                />
              </div>
              <div>
                <label>State</label>
                <input
                  type="text"
                  id="teamPhone"
                  className="registration-textbox"
                  placeholder="AZ"
                  required
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      address: { ...request.address, state: e.target.value },
                    })
                  }
                />
              </div>
              <div>
                <label>Zipcode</label>
                <input
                  type="text"
                  id="teamPhone"
                  className="registration-textbox"
                  placeholder="35555"
                  maxLength={10}
                  minLength={5}
                  required
                  onChange={(e) =>
                    setRequest({
                      ...request,
                      address: { ...request.address, zip: e.target.value },
                    })
                  }
                />
              </div>
            </div>
            <div className="form-section">
              <h4>Organization Admin</h4>
              <label>Admin First Name</label>
              <input
                type="text"
                id="adminFirstName"
                className="registration-textbox"
                required
                placeholder="Jane"
                onChange={(e) =>
                  setRequest({
                    ...request,
                    admin: { ...request.admin, firstName: e.target.value },
                  })
                }
              />
              <label>Admin Last Name</label>
              <input
                type="text"
                id="adminLastName"
                className="registration-textbox"
                required
                placeholder="Hancock"
                onChange={(e) =>
                  setRequest({
                    ...request,
                    admin: { ...request.admin, lastName: e.target.value },
                  })
                }
              />
              <label>Admin Email</label>
              <input
                type="email"
                id="email"
                className="registration-textbox"
                required
                placeholder="admin@org.com"
                onChange={(e) =>
                  setRequest({
                    ...request,
                    admin: { ...request.admin, email: e.target.value },
                  })
                }
              />

              <label>Profile Type</label>
              <select
                required
                onChange={(e) =>
                  setRequest({
                    ...request,
                    admin: {
                      ...request.admin,
                      profileType:
                        ProfileEnum[e.target.value as keyof typeof ProfileEnum],
                    },
                  })
                }
              >
                <option>Select A Profile</option>
                <option value="1">ADMIN</option>
                <option value="2">COACH</option>
                <option value="3">PLAYER</option>
                <option value="4">STAFF</option>
              </select>
            </div>

            <h4>Team(s)</h4>
            <label>League</label>
            <select
              required
              onChange={(e) =>
                setRequest({
                  ...request,
                  teams: [{ ...request.teams[0], league: e.target.value }],
                })
              }
            >
              <option>Select A League</option>
              <option value="aia">AIA</option>
              <option value="ncaa">NCAA</option>
              <option value="tssaa">TSSAA</option>
            </select>
            <div className={"team-rows"}>
              <Row>{teamRows}</Row>
              <a className="btn" onClick={() => handleAddTeamRow()}>
                Add Team
              </a>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={() => setModalOpen(true)}
            type="submit"
            className="btn"
          >
            Submit
          </button>
        </div>
      </form>
      <div>
        <Modal
          onCancel={() => {
            setModalOpen(false);
          }}
          onOk={() => {
            setModalOpen(false);
            handleFormSubmit();
          }}
          visible={modalOpen}
        >
          <p className={"modal-confirmation"}>
            Thank you for registering with We'll Call. On completion of your
            registration, the administrator and ticketing manager will receive
            an email to confirm their account. After confirmed, they will be
            allowed to add users. Enjoy!
          </p>
        </Modal>
      </div>
    </div>
  );
}
