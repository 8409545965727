import { unwrapResult } from "@reduxjs/toolkit";
import { DatePicker, notification } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, AppState } from "../../redux";
import { createScheduleAsync } from "../../redux/schedule/action";
import { initialCreateScheduleRequest } from "../../redux/schedule/reducer";
import { IScheduleState } from "../../redux/schedule/type";

export default function NewSchedule() {
  const dispatch: AppDispatch = useDispatch();
  const [scheduleRequest, setScheduleRequest] = useState(
    initialCreateScheduleRequest
  );

  const scheduleState: IScheduleState = useSelector(
    (state: AppState) => state.scheduleAsync
  );

  async function handleSubmit() {
    try {
      dispatch(createScheduleAsync(scheduleRequest)).then((result) => {
        notification.success({
          message: "Successfully Created Schedule",
          description:
            "Successfully created schedule. Feel free to add events to this schedule.",
        });
      });
    } catch (error) {
      notification.error({
        message: "Failed To Create Schedule",
        description: `Failed to create schedule. Please try again. ${error}`,
        duration: 10000,
      });
    }
  }

  return (
    <div>
      <h4>Add New Schedule</h4>
      <form>
        <div>
          <label>Schedule Name</label>
          <input
            type={"text"}
            id={"eventName"}
            placeholder={"2021 Season or 2021-2022 Season"}
            required
            onChange={(e) =>
              setScheduleRequest({ ...scheduleRequest, name: e.target.value })
            }
          />
        </div>
        <div className={"col-50"}>
          <label>Start Date</label>
          <div>
            <DatePicker
              aria-required
              dateRender={(current) => {
                const style = { border: "", borderRadius: "" };
                if (current.date() === 1) {
                  style.border = "1px solid #1890ff";
                  style.borderRadius = "50%";
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
              onSelect={(e) =>
                setScheduleRequest({
                  ...scheduleRequest,
                  startDate: new Date(e.toString()),
                })
              }
              inputReadOnly={true}
            />
          </div>
        </div>
        <div className={"col-50"}>
          <label>End Date</label>
          <div>
            <DatePicker
              aria-required
              dateRender={(current) => {
                const style = { border: "", borderRadius: "" };
                if (current.date() === 1) {
                  style.border = "1px solid #1890ff";
                  style.borderRadius = "50%";
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
              onSelect={(e) =>
                setScheduleRequest({
                  ...scheduleRequest,
                  endDate: new Date(e.toString()),
                })
              }
              inputReadOnly={true}
            />
          </div>
        </div>
      </form>
      <div>
        <button className={"btn"} onClick={() => handleSubmit()}>
          Add Schedule
        </button>
      </div>
    </div>
  );
}
