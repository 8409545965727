import { httpGetRequest, httpPostRequest } from "../../httpClient/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateEventRequest, CreateEventScheduleRequest, Event } from "./type";

export const createEventScheduleAsync = createAsyncThunk(
  "event/createEventScheduleAsync",
  async (request: CreateEventScheduleRequest, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/event/list", request.events);
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const createEventAsync = createAsyncThunk(
  "event/createEventAsync",
  async (request: CreateEventRequest, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/event", request);
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const updateEventAsync = createAsyncThunk(
  "event/createEventAsync",
  async (request: Event, { rejectWithValue }) => {
    try {
      let response = await httpPostRequest("api/event/update", request);
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getEventsScheduleAsync = createAsyncThunk(
  "event/getEventsScheduleAsync",
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/event/list",
        params: scheduleId,
      });
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getEventsByTeamIdAsync = createAsyncThunk(
  "event/getEventsByTeamIdAsync",
  async (teamId: string, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/event/list/season",
        params: { teamId: teamId },
      });
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getEventsForPublicSaleAsync = createAsyncThunk(
  "event/getEventsForPublicSaleAsync",
  async (teamId: string, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/event/list/public",
        params: { teamId: teamId },
      });
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getEventsScheduleDetailsAsync = createAsyncThunk(
  "event/getEventsScheduleDetailsAsync",
  async (scheduleId: string, { rejectWithValue }) => {
    try {
      let response = await httpGetRequest({
        url: "api/event/list/details",
        params: { scheduleId: scheduleId },
      });
      return response;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
