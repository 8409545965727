import React from "react";

export default function About() {
  return (
    <div className="about">
      <div>
        <h3>Origin Story</h3>
      </div>
      <div>
        <p>
          Growing up playing college football, founder Roderick "Roddy" Tomlin
          noticed that teams struggled to manage will call (also known as
          complimentary) tickets. These tickets are simply complimentary tickets
          athletes are giving to their loved ones to see them perform. However,
          due to handwriting, mismanagement, or sometimes last minute changes
          these tickets would not make it into the loved ones hands. How could
          this be? When Roddy began having conversations with athletes across
          other sports he knew it wasn't just an issue for the football team.
        </p>
        <p>
          When Roddy began his path towards the NFL, he immediately saw that
          nothing had changed with this management of tickets. He reached out
          again to some of other professional athletes from other sports and
          noticed the gap was still there. How could something so simple be so
          easily mismanaged? Is it because these are just free tickets? But
          these are the athletes that help make the organization. So he decided
          to build it himself.
        </p>
        <p>
          Like many others, he failed multiple times but continued to learn from
          the mistakes and harden the application. Here we are today, Not only
          looking to serve athletes, but performers of all kind. Your tickets,
          your events, We'll Call.
        </p>
      </div>
    </div>
  );
}
