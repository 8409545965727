import { TeamType } from "../redux/team/type";
import { ProfileEnum } from "../redux/user/type";

export function convertProfileToString(profileType: ProfileEnum) {
  switch (profileType) {
    case ProfileEnum.ADMIN:
      return "Admin";
    case ProfileEnum.COACH:
      return "Coach";
    case ProfileEnum.PLAYER:
      return "Player";
    case ProfileEnum.STAFF:
      return "Staff";
    case ProfileEnum.TEAMMANAGER:
      return "Team Manager";
    case ProfileEnum.TICKETHOLDER:
      return "Ticket Holder";
    case ProfileEnum.TICKETINGMANAGER:
      return "Ticketing Manager";
    default:
      return "Unknown";
  }
}

export function convertTeamTypeToString(teamType: TeamType) {
  switch (teamType) {
    case TeamType.AmericanFootball:
      return "Football";
    case TeamType.Cheerleading:
      return "Cheer";
    case TeamType.FootballSoccer:
      return "Soccer (Futbol)";
    case TeamType.MensBasketball:
      return "Basketball (M)";
    case TeamType.MensGolf:
      return "Golf (M)";
    case TeamType.MensSwimming:
      return "swimming (M)";
    case TeamType.WomensBasketball:
      return "Basketball (W)";
    case TeamType.WomensGolf:
      return "Golf (W)";
    case TeamType.WomensSwimming:
      return "Swimming (W)";
    default:
      return "Unknown";
  }
}
