import React from "react";
import "./contact.css";

export default function Contact() {
  return (
    <div className="contact">
      <div className={"col-33"}>
        <form>
          <div>
            <label>Name</label>
            <input
              type="text"
              id="Name"
              className="contact-text"
              required
              placeholder="John Doe"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div>
            <label>Email</label>
            <input
              type="text"
              id="Name"
              className="contact-text"
              required
              placeholder="your@email.com"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div>
            <label>Reason</label>
            <select className="">
              <option>Contact Reason</option>
              <option>General</option>
              <option>Customer Service</option>
              <option>Testimony</option>
              <option>Want Information</option>
            </select>
          </div>
          <div>
            <label>Message</label>
            <textarea
              id="Message"
              className="contact-message"
              required
              placeholder="Thank you for contacting us."
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
