import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getMyTixAsync,
  getTixForEventAsync,
  purchaseTixAsync,
  sendTicketAsync,
  validTicketAsync,
} from "./action";
import {
  ITicketState,
  PurchaseTixRequest,
  PurchaseTixResponse,
  SendTicketRequest,
  Ticket,
} from "./type";

export let initialSendTicketRequest: SendTicketRequest = {
  guest: {
    firstName: "",
    lastName: "",
    email: "",
    profileType: 0,
  },
  host: {
    firstName: "",
    lastName: "",
    email: "",
    profileType: 0,
  },
  event: {
    id: "",
    name: "",
    location: 0,
    ticketingManagerEmail: "",
    ticketingManagerName: "",
  },
};

export let initialPurchaseTixRequest: PurchaseTixRequest = {
  eventId: "",
  price: "",
  purchaseQuantity: 0,
  subtotal: "",
  purchasedTix: [],
};

export let initialPurchaseTixResponse: PurchaseTixResponse = {
  clientSecret: "",
  eventId: "",
  price: "",
  purchaseQuantity: "",
  subtotal: "",
};

export let initialTicket: Ticket = {
  id: "",
  ticketType: "",
  host: {
    firstName: "",
    lastName: "",
    email: "",
    profiles: [],
    id: "",
  },
  guest: {
    firstName: "",
    lastName: "",
    email: "",
    profiles: [],
    id: "",
  },
  event: {
    name: "",
    location: 0,
    ticketingManagerEmail: "",
    ticketingManagerName: "",
    id: "",
    teamId: "",
    organizationId: "",
    maxTicketPerIndividual: 0,
    createdTime: "",
    isForPublicSale: false,
    eventDate: "",
    publicSaleMeta: {
      maxTicketsForSale: 0,
      pricePerTicket: "0.00",
    },
  },
};

export const initialState: ITicketState = {
  isLoading: false,
  error: "",
  sendTicketRequest: initialSendTicketRequest,
  sendTicketResponse: initialTicket,
  validTicketResponse: initialTicket,
  purchaseTixRequest: initialPurchaseTixRequest,
  purchaseTixResponse: initialPurchaseTixResponse,
  getTixForEventResponse: [],
  getMyTixResponse: [],
};

const TicketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {},
  extraReducers: {
    [sendTicketAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Ticket>
    ) => {
      state.sendTicketResponse = action.payload;
    },
    [purchaseTixAsync.pending.toString()]: (
      state,
      action: PayloadAction<PurchaseTixRequest>
    ) => {
      state.purchaseTixRequest = action.payload;
    },
    [purchaseTixAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<PurchaseTixResponse>
    ) => {
      state.purchaseTixResponse = action.payload;
    },
    [validTicketAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Ticket>
    ) => {
      state.validTicketResponse = action.payload;
    },
    [getTixForEventAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Ticket>>
    ) => {
      state.getTixForEventResponse = action.payload;
    },
    [getMyTixAsync.fulfilled.toString()]: (
      state,
      action: PayloadAction<Array<Ticket>>
    ) => {
      state.getMyTixResponse = action.payload;
    },
    [validTicketAsync.rejected.toString()]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.validTicketResponse = action.payload;
    },
  },
});

export default TicketSlice.reducer;
