export interface Team {
  id: string;
  name: string;
  type: TeamType;
}

export enum TeamType {
  UNKNOWN,
  MensBasketball,
  WomensBasketball,
  AmericanFootball,
  FootballSoccer,
  MensSwimming,
  WomensSwimming,
  MensGolf,
  WomensGolf,
  Cheerleading,
}

export interface ITeamState {
  getTeamsForOrganizationResponse: Team[];
  isLoading: boolean;
  error: any;
}
