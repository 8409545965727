import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { initialSendTicketRequest } from "../../redux/ticket/reducer";

export default function NewTicket(props: {
  sendInvite: any;
  onCancel: any;
  visible: boolean;
}) {
  const [invite, setInvite] = useState(initialSendTicketRequest);
  const [modalVis, setModalVis] = useState(true);

  return (
    <Modal
      onCancel={() => props.onCancel(false)}
      destroyOnClose
      onOk={() => props.sendInvite(invite)}
      visible={props.visible}
    >
      <form>
        <div>
          <label>First Name</label>
          <input
            type={"text"}
            id={"inviteFirstName"}
            placeholder={"Jane"}
            required
            onChange={(e) =>
              setInvite({
                ...invite,
                guest: { ...invite.guest, firstName: e.target.value },
              })
            }
          />
        </div>
        <div>
          <label>Last Name</label>
          <input
            type={"text"}
            id={"inviteLastName"}
            placeholder={"Doe"}
            required
            onChange={(e) =>
              setInvite({
                ...invite,
                guest: { ...invite.guest, lastName: e.target.value },
              })
            }
          />
        </div>
        <div>
          <label>Email</label>
          <input
            type={"text"}
            id={"inviteEmail"}
            placeholder={"jane@doe.com"}
            required
            onChange={(e) =>
              setInvite({
                ...invite,
                guest: { ...invite.guest, email: e.target.value },
              })
            }
          />
        </div>
      </form>
    </Modal>
  );
}
