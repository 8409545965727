import { Organization } from "../organization/type";
import { Team } from "../team/type";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  profiles: UserProfile[];
}

export interface GetMeResponse {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  profiles: UserProfile[];
  organizations: Organization[];
  teams: Team[];
}

export interface UserProfile {
  id: string;
  profileType: ProfileEnum;
  organizationId: string;
  teamId: string;
}

export enum ProfileEnum {
  UNKNOWN = 0,
  ADMIN,
  COACH,
  PLAYER,
  STAFF,
  TICKETINGMANAGER,
  TEAMMANAGER,
  TICKETHOLDER,
}

export interface CreateUserRequest {
  firstName: string;
  lastName: string;
  email: string;
  profileType: ProfileEnum;
}

export interface CreateRosterRequest {
  users: CreateUserRequest[];
}

export interface CreateRosterResponse {
  users: User[];
}

export interface IUserState {
  isLoading: boolean;
  error: any;
  createUserRequest: CreateUserRequest;
  createUserResponse: User;
  createRosterRequest: CreateRosterRequest;
  createRosterResponse: CreateRosterResponse;
  getMyTeamResponse: Array<User>;
  getMeResponse: GetMeResponse;
}
